import {
    INotificationsFireInfo,
    IPreNoticesDate,
    INotificationClient,
    INoticesByDateParam,
    IFirePerimeter,
    INotifications
} from "./models";

import { getJson, postJson, postJsonWithNoTrack } from "utils";
import { getJSON } from "jquery";

export async function getFireInfo(id: number): Promise<INotificationsFireInfo> {
    var result = await getJSON("/api/notifications/fires/GetFireInfo/" + id);
    return result ?? {};
}

export async function getPreNoticesDate(fireId: number): Promise<IPreNoticesDate[]> {
    return postJson("/api/notifications/fires/GetPreNoticesDate",
        {
            Filter: {
                fireId: fireId
            }
        })
        .then(result => result ?? []);
}

export async function getNotificationDefaultValues(param: INoticesByDateParam): Promise<string[]> {
    return getJson("/api/notifications/fires/GetNotificationDefaultValues?fireId=" + param.fireId + "&dateCreated=" + param.dateCreated)
        .then(result => result?.data);
}

export async function getNoticesByDate(param: INoticesByDateParam): Promise<INotificationClient[]> {

    return postJson("/api/notifications/fires/GetNoticesByDate",
        {
            Filter: {
                fireId: param.fireId,
                dateCreated: param.dateCreated
            }
        })
        .then(result => result ?? []);
}

export async function getLatestNotices(fireId: number): Promise<INotificationClient[]> {

    return postJson("/api/notifications/fires/GetLatestNotices",
        {
            Filter: {
                fireId: fireId
            }
        })
        .then(result => result?.data);
}

export async function getAllClients(): Promise<any> {
    return getJson("/api/notifications/fires/GetAllClients").then(result => result?.data);
}

export async function saveNotifications(notifications: INotifications): Promise<number[]> {

    return postJson("/api/notifications/fires/SaveNotifications", notifications)
        .then(result => result?.data);
}

/* Gis analysis and save triggered */
export async function saveTriggered(noticeIds: number[]): Promise<any> {
    return postJson('/api/notifications/fires/SaveTriggered', noticeIds);
}

export async function checkIfFunctionAppCompleted(statusQueryGetUri: string): Promise<any> {
    return postJsonWithNoTrack('/api/notifications/fires/CheckIfFunctionAppCompleted', { url: statusQueryGetUri }).then(res => res.json());
}

export async function getPerimeterByFire(id: number): Promise<IFirePerimeter> {
    var result = await getJSON("/api/notifications/fires/getPerimeterByFire/" + id);
    return result ?? {};
}

export async function getPerimeterById(id: number): Promise<IFirePerimeter> {
    var result = await getJSON("/api/notifications/fires/GetPerimeterById/" + id);
    return result ?? {};
}

