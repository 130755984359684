import { getJson, postJson } from "utils";
import { IEmployee, IHistoryRO, IResEngine, IScheduleDetail } from "./models";

/*Resource list*/
export interface IResourceListParams {
    searchKey?: string,
    sortBy?: string,
    resourceType?: string[],
    engineStatus?: string[],
    rangeFrom?: string,
    rangeTo?: string
}
export async function getReourceList(param: IResourceListParams): Promise<IResEngine[]> {
    return postJson("/api/ResourceScheduling/Resources/GetList",
        {
            Filter: {
                SearchKey: '',
                SortBy: param.sortBy,
                ResourceType: param.resourceType,
                EngineStatus: param.engineStatus,
                RangeFrom: param.rangeFrom,
                RangeTo: param.rangeTo
            }
        })
        .then(result => result?.data);
}

export async function getOnlyReourceList(param: IResourceListParams): Promise<IResEngine[]> {
    return postJson("/api/ResourceScheduling/Resources/GetOnlyResourceList",
        {
            Filter: {
                SearchKey: param.searchKey,
                SortBy: param.sortBy,
                RangeFrom: param.rangeFrom,
                RangeTo: param.rangeTo
            }
        })
        .then(result => result?.data);
}

/* Schedule Dropdown options*/
export async function GetOptionsData(engineId: any, fireId: any): Promise<any> {
    return postJson("/api/ResourceScheduling/Schedule/GetOptionsData", {engineId, fireId})
        .then(result => result?.data);
}

/* Get employee list */
export async function GetEmployeeList(searchKey?: string, sortBy?: string): Promise<IEmployee[]> {
    return postJson("/api/ResourceScheduling/Personal/PersonalList",
        {
            Filter: {
                SearchKey: searchKey ?? '',
                SortBy: sortBy ?? ''
            }
        })
        .then(result => result?.data);
}

/* Get schedule detail */
export async function GetScheduleDetail(scheduleId: number): Promise<IScheduleDetail> {
    return getJson("/api/ResourceScheduling/Schedule/GetScheduleDetail?scheduleId=" + scheduleId)
        .then(result => result?.data);
}

/* Save schedule detail */
export async function SaveScheduleDetail(schedule: IScheduleDetail): Promise<any> {
    return postJson("/api/ResourceScheduling/Schedule/SaveSchedule", schedule)
        .then(result => result?.data);
}

/* Delete schedule */
export async function DelScheduleDetail(scheduleId: number): Promise<any> {
    return getJson("/api/ResourceScheduling/Schedule/RemoveSchedule?scheduleId=" + scheduleId)
        .then(result => result?.data);
}

/* /Schedule/CheckDateRangeAvailable */
export async function CheckDateRangeAvailable(engineId: number, dates: string[], scheduleId?: number): Promise<any> {
    let data = {
        scheduleId: scheduleId,
        engineId: engineId,
        assignment: null as any,
        hold: null as any,
    }
    if (dates[0]?.length && dates[1]?.length) {
        data.assignment = {
            startDate: dates[0],  //Assignment start datetime
            endDate: dates[1] //Assignment end datetime
        };
    }
    if (dates[2]?.length && dates[3]?.length) {
        data.hold = {
            startDate: dates[2],  //OnHold start datetime
            endDate: dates[3] //OnHold end datetime
        };
    }
    return postJson("/api/ResourceScheduling/Schedule/CheckDateRangeAvailable", data)
        .then(result => result?.data);
}

/* /Personal/CheckDateRangeAvailable */
export interface ICheckCrewDateParams {
    CrewIds: number[],
    ScheduleId?: number,
    DateTimeRange: {
        StartDate: string,
        EndDate: string
    },
    IsRecurring?: boolean
}
export async function CheckCrewDateRangeAvailable(params: ICheckCrewDateParams): Promise<any> {
    return postJson("/api/ResourceScheduling/Personal/CheckDateRangeAvailable", params)
        .then(result => result?.data);
}

/** Get History of RO */
export async function GetHistoryRO(engineId: number): Promise<IHistoryRO[]> {
    return getJson("/api/ResourceScheduling/Resources/RoHistory?resourceId=" + engineId).then(result => result?.data);
}


/* Which crews touch DOT regulation deadline and what day */
export interface IDotWarningParams {
    CrewIds?: number[],
    ViewStartDate: string,
    ViewEndDate: string
}
export async function CrewsWithDotWarning(params: IDotWarningParams): Promise<any> {
    return postJson("/api/ResourceScheduling/Personal/CrewsWithDotWarning", { Filter: params })
        .then(result => result?.data);
}

export async function getDatesWithVisits(data: any): Promise<any> {
    const visit = await postJson('/api/ResourceScheduling/Schedule/getDatesWithVisits', data).then(res => res || []);
    return visit;
}