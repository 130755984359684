import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IPHVisitDetail, IPHVisit, IActionTypesMap, IPhoto, IVisitIdStatus } from "api/models";
import { getPHVisitDetails, getHomeStatusOptions, getActionTypes, saveVisitDetail as saveVisitDetailAsync, getVisitsStatuses, getFireNames, getPropertyAddresses, getEngines } from "api/phaAPI";

interface IPHAState {
    homeStatusOptions: any[];
    actionTypesMap?: IActionTypesMap;
    selectedVisit?: IPHVisit;    //currently selected visit
    visitWithDetail?: IPHVisitDetail;
    nextRowNumber?: number;
    isLastRow?: boolean;
    imageToEdit: IPhoto;
    updatedVisit?: Partial<IPHVisit>;
    visitIdStatuses?: IVisitIdStatus[];
    fireNames?: any;
    propertyAddresses?: any;
    engines?: any;
    PHVisits?: any;
}

const initialState: IPHAState = {
    homeStatusOptions: [],
    imageToEdit: {},
    PHVisits: []
}

export const fetchVisitDetails = createAsyncThunk('actions/fetchVisitDetails', async (visitId: number) => {
    const detail = await getPHVisitDetails(visitId);
    if (detail?.photos?.length) {
        detail.photos = detail.photos.filter((p: IPhoto) => p.blobPath && p.blobThumbPath);
        detail.photos.forEach((p: IPhoto) => {
            p.blobThumbDataUrl = p.blobThumbPath;
        });
    }
    if (detail?.previousPhotos?.length) {
        detail.previousPhotos = detail.previousPhotos.filter((p: IPhoto) => p.blobPath && p.blobThumbPath);
        detail.previousPhotos.forEach((p: IPhoto) => {
            p.blobThumbDataUrl = p.blobThumbPath;
        });
    }
    return detail;
});

export const fetchHomeStatus = createAsyncThunk('actions/fetchHomeStatus', async () => {
    let response = await getHomeStatusOptions();
    return response || [];
});

export const fetchActionTypes = createAsyncThunk('actions/fetchActionTypes', async () => {
    let response = await getActionTypes();
    return response || {};
});

export const fetchVisitIdStatus = createAsyncThunk('actions/fetchVisitIdStatus', async (ids: number[]) => {
    let response = await getVisitsStatuses({
        filter: { visits: ids }
    });
    return response?.data || [];
});

export const saveVisitDetail = createAsyncThunk('actions/saveVisitDetail', async (detail: Partial<IPHVisitDetail>) => {
    await saveVisitDetailAsync(detail);
});

export const fetchFireNames = createAsyncThunk('actions/fetchFireNames', async (clientId: number) => {
    let response = await getFireNames(clientId);
    return response || {};
});

export const fetchPropertyAddresses = createAsyncThunk('actions/fetchPropertyAddresses', async (data: any) => {
    let response = await getPropertyAddresses(data);
    return response || {};
});

export const fetchEngines = createAsyncThunk('actions/fetchEngines', async (data: any) => {
    let response = await getEngines(data);
    return response || {};
});

const actions = createSlice({
    name: "actions",
    initialState,
    reducers: {
        selectVisit: (state, action) => {
            state.selectedVisit = action.payload;
        },
        setNextRowNumber: (state) => {
            state.nextRowNumber = new Date().getTime();
        },
        setReachLastRow: (state, action) => {
            state.isLastRow = action.payload;
        },
        setPHVisits: (state, action) => {
            state.PHVisits = action.payload;
        },
        selectImageToEdit: (state, action) => {
            state.imageToEdit = action.payload as IPhoto;
        },
        setVistiDetailPhotoThumbnail: (state, action) => {
            const { id, blobThumbPath } = action.payload;
            if (state.visitWithDetail?.photos?.length) {
                state.visitWithDetail.photos.forEach((p: IPhoto) => {
                    if (p.id === id) {
                        p.blobThumbDataUrl = blobThumbPath;
                    }
                });
            }
        },
        setUpdateVisitDetail: (state, action) => {
            if (state.visitWithDetail) {
                const { homeStatus, publishComments, reviewStatus, actionTypes } = action.payload;
                state.visitWithDetail.homeStatus = homeStatus;
                state.visitWithDetail.publishComments = publishComments;
                state.visitWithDetail.actionTypes = actionTypes;
                state.visitWithDetail.reviewStatus = reviewStatus;
            }
        },
        setVisitIdStatuses: (state, action) => {
            state.visitIdStatuses = action.payload;
        },
        setUpdatedVisit: (state, action) => {
            const { id, reviewStatus } = action.payload;
            state.updatedVisit = state.updatedVisit || {};
            state.updatedVisit.id = id;
            state.updatedVisit.reviewStatus = reviewStatus;
        }
    },
    extraReducers: builder => {
        //fetchVisitDetails
        builder
            .addCase(fetchVisitDetails.fulfilled, (state, action) => {
                state.visitWithDetail = action.payload;
            })
            //fetchHomeStatus
            .addCase(fetchHomeStatus.fulfilled, (state, action) => {
                state.homeStatusOptions = action.payload
            })
            //fetchActionTypes
            .addCase(fetchActionTypes.fulfilled, (state, action) => {
                state.actionTypesMap = action.payload;
            })
            //fetchVisitIdStatus
            .addCase(fetchVisitIdStatus.fulfilled, (state, action) => {
                state.visitIdStatuses = action.payload;
            })
            //fetchFireName for new PHA form
            .addCase(fetchFireNames.fulfilled, (state, action) => {
                state.fireNames = action.payload;
            })
            //fetchFireName for new PHA form
            .addCase(fetchPropertyAddresses.fulfilled, (state, action) => {
                state.propertyAddresses = action.payload;
            })
            //fetchEngines for new PHA form
            .addCase(fetchEngines.fulfilled, (state, action) => {
                state.engines = action.payload;
            })
    }
});

export const {
    selectVisit,
    setReachLastRow,
    setNextRowNumber,
    selectImageToEdit,
    setVistiDetailPhotoThumbnail,
    setUpdateVisitDetail,
    setUpdatedVisit,
    setVisitIdStatuses,
    setPHVisits
} = actions.actions;
export default actions.reducer;