import { mergeStyles, Stack, Text } from "@fluentui/react";

export const AccessDenied = () => {
    const className = mergeStyles({ margin: '50px auto', backgroundColor: '#fff', color: '#000' });

    return (
        <Stack horizontalAlign='center' className={className}>
            <Text>Access denied!!</Text>
        </Stack>
    );
}