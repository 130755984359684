import { getJson, postDownload, postJson } from "utils";
import { IExcel, IPagination, IPhoto, IPropertyAccess, IPropertyList, IPspQueryParam, IState } from "./models";

export interface VisitInfo {
    id: number;
    pid: number;
    client: number;
    fireName: number;
    dateAction: string;
    status: string;
    comments: string;
    actionsTaken: string;
    photos: IPhoto[];
    setPhoto: React.Dispatch<React.SetStateAction<number>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setPhotos: React.Dispatch<React.SetStateAction<IPhoto[]>>;
}

export async function getStates(client_ids: number[]): Promise<IState[]> {
    let result = await postJson('api/StatesByClient', client_ids);
    return result ?? {}
}

export async function getPropertyList(params: IPspQueryParam): Promise<IPagination<IPropertyList>> {
    const { pagination, Filter, Sorting } = params;
    if (pagination && (isNaN(pagination.pageNumber) || pagination.pageNumber <= 0)) {
        pagination.pageNumber = 1
    }
    if (pagination && (isNaN(pagination.pageSize) || pagination.pageSize <= 0)) {
        pagination.pageSize = 10
    }
    return postJson(
        '/api/propertysearch/property/propertyList',
        {
            pagination: pagination,
            Filter: Filter,
            Sorting: !Sorting || Sorting[0].Name.length === 0 ? null : Sorting
        }
    );
}

export async function getEnrollmentHistory(pid: number): Promise<any> {
    return postJson(
        '/api/propertysearch/property/getEnrollmentHistory', pid
    )
}

export async function getVisitHistory(pid: number, clientIds: number[]): Promise<any> {
    return postJson(
        '/api/propertysearch/property/getVisitHistory', {pid, clientIds}
    )
}

export async function getFireThreats(pid: number, clientIds: number[]): Promise<any> {
    return postJson(
        '/api/propertysearch/property/getFireThreats', {pid, clientIds}
    )
}

export function exportToExcel(params: IPspQueryParam) {
    const { Filter, Sorting } = params;
    const fireName = `unmatched.xlsx`;
    const excelData: IExcel = {
        name: fireName,
        sheets: [],
      };
    postJson(
        '/api/propertysearch/property/exportPropertyList',
        {
            Filter: Filter,
            Sorting: !Sorting || Sorting[0].Name.length === 0 ? null : Sorting
        }
    ).then((res: [{
        id: number,
        addressLine1: string,
        addressLine2: string,
        city: string,
        county: string,
        state: string,
        policy: string,
        zip: string,
        responseStatus: string,
        location: string,
        producer: string,
        agencyName: string,
        agencyCode: string,
    }]) => {
        excelData.sheets.push({
            name: 'Unmatched Properties',
            data: [['Address Line 1', 'Address Line 2', 'City', 'County', 'State', 'Zip', 
                    'Response Status', 'Producer', 'Agency Name', 'Agency Code', 'Policy Number', 'Location'], ...(res|| []).map((s) => [s.addressLine1, s.addressLine2, s.city, s.county, s.state, s.zip, s.responseStatus, s.producer, s.agencyName, s.agencyCode, s.policy, s.location])]
          });
        postDownload("/api/parseExcel", fireName, excelData);
    })
}

export async function getNotesSize(): Promise<any> {
    let variable = postJson('/api/propertysearch/property/getNotesLength', {});
    return variable;
}

export async function getPropertyAccess(pid: number): Promise<IPropertyAccess> {
    return postJson(
        '/api/propertysearch/property/propertyAccess',
        {
            Filter: { propertyId: pid },
        }
    );
}
export async function savePropertyAccess(model: IPropertyAccess): Promise<number> {
    return postJson(
        '/api/propertysearch/property/updatePropertyAccess',
        {
            ...model
        }
    );
}
export async function fetchPropertyPolicies(pid: number): Promise<any> {
    return postJson(
        '/api/propertysearch/property/fetchPropertyPolicies',
        {
            Filter: {
                PropertyId: pid
            }
        }
    );
}
export async function getPropertyLocation(pid: number): Promise<IPropertyList> {
    return getJson(
        '/api/propertysearch/property/PropertyInfo/' + pid,
    );
}
export async function savePropertyLocation(model: any): Promise<number> {
    return postJson(
        '/api/propertysearch/property/updatePropertyCoordinate',
        {
            ...model
        }
    );
}
export async function createFireEnrollment(model: any): Promise<number> {
    return postJson(
        '/api/propertysearch/property/createfireenrollment',
        {
            ...model
        }
    );
}
export async function createNewProperty(model: any): Promise<number> {
    return postJson(
        '/api/propertysearch/property/createProperty',
        {
            ...model
        }
    );
}
export async function fetchCallList(pid: any): Promise<any> {
    return postJson(
        '/api/propertysearch/property/fetchCallList',
        {
            Filter: {
                PropertyId: pid
            }
        }
    )
    .then(res => res?.data);
}
export async function fetchCallLogFires(clientId: any): Promise<any> {
    return postJson(
        '/api/propertysearch/property/fetchCallLogFires',
        {
            Filter: {
                ClientId: clientId
            }
        }
    )
    .then(res => res?.data);
}
export async function saveCallLog(data: any): Promise<any> {
    return postJson(
        '/api/propertysearch/property/saveCallLog', data
    )
    .then(res => res?.data);
}
export async function getPrePlan(pid: number): Promise<any> {
    return getJson(
        '/api/propertysearch/property/GetPrePlan/' + pid,
    );
}
export async function getBlobSASUrl(blobPath: string): Promise<any> {
    return postJson('/api/propertysearch/property/uploadpreplan', { blobPath });
}
export async function saveUploadedPrePlan(newPrePlan: any, propertyId: number): Promise<any> {
    return await postJson('/api/propertysearch/property/saveuploadedpreplan', { newPrePlan, propertyId });
}
export async function followingProperty(data: any): Promise<any> {
    return await postJson('/api/propertysearch/property/followingproperty', data);
}
export async function followProperty(data: any): Promise<any> {
    return await postJson('/api/propertysearch/property/followproperty', data);
}
export async function unFollowProperty(data: any): Promise<any> {
    return await postJson('/api/propertysearch/property/unfollowproperty', data);
}
export async function getActiveIncidents(data: any): Promise<any> {
    return await postJson('/api/propertysearch/property/getactiveincidents', data);
}