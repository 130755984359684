import React, { useState } from "react";
import { useAppSelector, useAuthContext } from "app/hooks";
import { Link, Modal, IconButton, IIconProps, MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Constants } from "utils/constants";
import { useCookies } from "react-cookie";
import { RootState } from "app/store";

export default function Footer() {
    const ctx = useAuthContext();
    const { title } = useAppSelector((state: RootState) => state.global);
    const [cookies, setCookies] = useCookies([Constants.Cookie_Consent]);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const cookieConsent = cookies[Constants.Cookie_Consent];
    const [consentVisible, setConsentVisible] = useState(true);

    const consent = () => {
        setCookies(Constants.Cookie_Consent, true, {expires: new Date(new Date().getTime() + 2592000000)});
        setConsentVisible(false);
    }
    return (
        <>
            {/* <div className="containerbottom"></div> */}
            <div className="wdsfooter text-center" id="footer">
            <div className="copyright">Copyright &copy; {new Date().getFullYear()} Wildfire Defense Systems - All Rights Reserved
                </div>
            {ctx.site == 'External' &&
                <>
                    <div className="terms-conditions">
                        <span onClick={showModal}>Terms and Conditions</span>
                    </div>    
                    <Modal
                        titleAriaId="disclaimer"
                        isOpen={isModalOpen}
                        onDismiss={hideModal}
                        isBlocking={false}
                        containerClassName="disclaimer"
                    >
                        <div className="disclaimer-content">
                            <div className="disclaimer-heading">
                                <h3>Terms & Conditions</h3>
                                <IconButton
                                    className="disclaimer-close"
                                    iconProps={cancelIcon}
                                    ariaLabel="Close"
                                    onClick={hideModal}
                                />
                            </div>
                            <div className="disclaimer-text">
                                <p>
                                    Disclaimer: This information, including any comments or photographs, is for internal use only by the recipient stated above. Do not distribute this information, whether physical or electronic, to any person, entity, or organization outside of the recipient stated above without proper authorization. This information is in no way a representation or warranty of specific actions or services being provided by Wildfire Defense Systems, Inc. and is provided for informational purposes only.
                                </p>
                            </div>
                        </div>
                    </Modal>
                </>
            }
            {ctx.site == 'External' && !cookieConsent && consentVisible &&
                <MessageBar
                    className="cookie-consent"
                    messageBarType={MessageBarType.warning}
                    isMultiline={false}
                    onDismiss={consent}
                    dismissButtonAriaLabel="Close"
                    actions={
                    <div>
                        <MessageBarButton onClick={consent}>Ok</MessageBarButton>
                    </div>
                    }
                >
                    We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.
                </MessageBar>
            }
            </div>
        </>
    );
}