import {
    IFireOverviewKpiBox,
    IFireOverviewParams,
    IFireOverviewFireInfo,
    IFirePerimeter,
    IPerimeterLocation,
    IPropertyQuery,
    IPropertyCity,
    ILob,
    IPropertyInFire,   
    INoticeResult,
    IVisit,
    IVisitResult
} from "./models";

import { getJson, postJson, postJsonWithNoTrack, downloadKMZ } from "utils";
import { getJSON } from "jquery";

/*Check if have access*/
export async function checkIfHasAccess(params: IFireOverviewParams): Promise<string> {    
    return postJson(`/api/FireOverview/FireOverview/CheckIfHasAccess`, params);
}

/*KpiBox*/
export async function getKpiBoxViewModel(params: IFireOverviewParams): Promise<IFireOverviewKpiBox> {
    return postJson(`/api/FireOverview/KpiBox/GetKpiBoxViewModel`, params);   
}

export async function saveKpiConfigration(configration: any) {
    postJsonWithNoTrack(
        '/api/FireOverview/KpiBox/SaveKpiBoxConfig',
        { configration }
    );
}
export async function getKpiConfigration(): Promise<any> {
    return getJson('/api/FireOverview/KpiBox/GetKpiBoxConfig');
}
/*End KpiBox */

/*Azure Map */
export async function getPreviousPerimeterByFire(id: number): Promise<IPerimeterLocation[]> {
    var result = await getJSON("/api/FireOverview/FireOverview/GetPreviousPerimeterByFire/" + id);
    return result ?? {};
}

export async function getPerimeterByFire(id: number): Promise<IFirePerimeter> {
    var result = await getJSON("/api/FireOverview/FireOverview/getPerimeterByFire/" + id);
    return result ?? {};
}

export async function getPerimeterById(id: number): Promise<IFirePerimeter> {
    var result = await getJSON("/api/FireOverview/FireOverview/GetPerimeterById/" + id);
    return result ?? {};
}

export async function getZipCode(params: any): Promise<any> {
    var result = await postJson(
        '/api/FireOverview/FireOverview/GetZipCodes',
        { ...params }
    );
    return result ?? {};
}
export async function getVisitsByPropertyId(param: IVisitParam): Promise<IVisit[]> {
    return postJson("/api/FireOverview/FireOverview/GetVisits",
        {
            Filter: { fireId: param.fireId, clientId: param.clientId, propertyId: param.propertyId },
            Sorting: [
                { Name: "RealVisitDate", IsDesc: true }
            ]
        })
        .then(result => result?.data?.data);
}

export interface IThreatenedParam {
    fireId: number;
    clientId: number;
    propertyId?: number;    
}

export async function getThreatenedMessage(param: IThreatenedParam): Promise<string[]> {
    return getJson("/api/FireOverview/FireOverview/GetThreatenedMessage?fireId=" + param.fireId + "&clientId=" + param.clientId + "&propertyId=" + param.propertyId)
        .then(result => result?.data);
}
/*End Azure Map */

/* Fire Information */
export async function getFireInformation(params: IFireOverviewParams): Promise<IFireOverviewFireInfo> {
    var result = await postJson(`/api/FireOverview/FireOverview/GetFireOverviewFireInfoViewModel`, params);
    return result ?? {};
}
/* End Fire Information */

/** Property Queries Start */
export async function initPropertyQuery(): Promise<any> {
    return postJson("/api/FireOverview/FireOverview/InitDbWithPropertyQuery", {});
}

export interface IPropertyQueryParam {
    fireId: number;
    clientId: number;
}

export async function getPropertyQueries(param: IPropertyQueryParam): Promise<IPropertyQuery[]> {
    return postJson("/api/FireOverview/FireOverview/GetPropertyQueries", { Filter: { fireId: param.fireId, clientId: param.clientId } })
        .then(result => result?.data?.data);
}

export async function savePropertyQuery(param: IPropertyQuery): Promise<number> {
    return postJson("/api/FireOverview/FireOverview/SavePropertyQuery", param)
        .then(result => result?.data);
}

export async function deletePropertyQuery(key: number): Promise<number> {
    return postJson("/api/FireOverview/FireOverview/DeletePropertyQuery", key)
        .then(result => result?.data);
}

export interface IPropertyCityParam {
    fireId: number;
    clientId: number;
}
export async function getPropertyCities(param: IPropertyCityParam): Promise<IPropertyCity[]> {
    return postJson("/api/FireOverview/FireOverview/GetPropertyCities", { Filter: { fireId: param.fireId, clientId: param.clientId } })
        .then(result => result?.data?.data);
}

export async function getAllLob(): Promise<ILob[]> {
    return getJson("/api/FireOverview/FireOverview/GetAllLob")
        .then(result => result?.data);
}

export interface IPropertyInFireParam {
    fireId: number;
    clientId: number;
    cities?: string;
    threatened?: boolean;
    lastPropertyStatus?: string;
    propertyTypes?: string;
    fireName?: string;
    perimeterId?: number;
}

export async function getPropertyInFire(param: IPropertyInFireParam): Promise<IPropertyInFire[]> {
    return postJson("/api/FireOverview/FireOverview/GetPropertyInFire", {Filter: param})
        .then(result => result?.data?.data);
}

export async function downloadKml(param: IPropertyInFireParam) {

    const url = '/api/FireOverview/FireOverview/DownloadKML';

    downloadKMZ(url, param);

}

/** Property Queries End */

/** Pre Fire Information Start */
export interface IPublishedNoticeParam {
    fireId: number;
    clientId?: number;
    from?: string;
    to?: string;
    pageSize: number;
    pageNumber: number;
}
export async function getPublishedNotices(param: IPublishedNoticeParam): Promise<INoticeResult> {
    return postJson("/api/FireOverview/FireOverview/GetPublishedNotices", {
        Filter: param,
        Pagination: { pageSize: param.pageSize, pageNumber: param.pageNumber },
        Sorting: [
            { Name: "DTPublicationDate", IsDesc: true },
        ]
    })
        .then(result => {
            return {
                dataset: result?.data?.data,
                totalCount: result?.data?.meta.totalCount
            }
        });
}

export interface IFromToParam {
    fireId: number;
    clientId?: number;
}

export interface IDateTimeCollection {
    datetimeStr: string[];
    datetimeValue: number[];
}

export async function getFromToDate(param: IFromToParam): Promise<IDateTimeCollection> {
    return getJson("/api/FireOverview/FireOverview/GetFromToDate?fireId=" + param.fireId + "&clientId=" + param.clientId)
        .then(result => {
            return {
                datetimeStr: result?.data,
                datetimeValue: result?.valueList
            }
        });
}

export interface IMDSTParam {
    fireId: number;
    clientId?: number;
    date: string;
    maxTime: boolean;
}

export async function getMDSTofTime(param: IMDSTParam): Promise<string> {
    return postJson("/api/FireOverview/FireOverview/GetMDSTofTime", param)
        .then(result => result?.data);
}

export async function mountainTimeFromTimestamp(param: number): Promise<string> {
    return postJson("/api/FireOverview/FireOverview/DisplayMTFromTimestamp", param)
        .then(result => result?.data);
}

export async function getIsExternal(): Promise<boolean> {
    return getJson("/api/FireOverview/FireOverview/IsExternal")
        .then(result => result?.data);
}
/** Pre Fire Information End */

/** See all visits Start */

export interface IVisitParam {
    fireId: number;
    clientId?: number;
    visitId?: number;
    propertyId?: number;
    selectedVisitDate?: string;
    pageSize?: number;
    pageNumber?: number;
}

export async function getVisits(param: IVisitParam): Promise<IVisitResult> {
    return postJson("/api/FireOverview/FireOverview/GetVisits",
        {
            Filter: {
                fireId: param.fireId,
                clientId: param.clientId,
                selectedVisitDate: param.selectedVisitDate,
                visitId: param.visitId,
                propertyId: param.propertyId
            },
            Pagination: { pageSize: param.pageSize, pageNumber: param.pageNumber },

            Sorting: [
                { Name: "Address", IsDesc: false },
                { Name: "RealVisitDate", IsDesc: true }
            ]
        })
        .then(result => {
            return {
                dataset: result?.data?.data,
                totalCount: result?.data?.meta.totalCount
            }
        });
}

export async function getVisitDates(param: IFromToParam): Promise<string[]> {
    return getJson("/api/FireOverview/FireOverview/GetVisitDates?fireId=" + param.fireId + "&clientId=" + param.clientId)
        .then(result => result?.data);
}

export async function getVisitsAll(param: IVisitParam): Promise<IVisitResult> {
    return postJson("/api/FireOverview/FireOverview/VisitAllDataForPdf",
        {
            Filter: {
                fireId: param.fireId,
                clientId: param.clientId
            },
            Pagination: { pageSize: param.pageSize, pageNumber: param.pageNumber },
            Sorting: [
                { Name: "Address", IsDesc: false },
                { Name: "RealVisitDate", IsDesc: true }
            ]
        })
        .then(result => {
            return {
                dataset: result?.data?.data,
                totalCount: result?.data?.meta.totalCount
            }
        });
}
/** See all visits End */

/** Client dropdown Start **/
export async function getValidClientIds(fireId: number): Promise<number[]> {
    return getJson(`/api/FireOverview/FireOverview/GetValiadClientIds/?fireId=${fireId}`);
}
/** Client dropdown End **/
