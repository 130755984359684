import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFireOverviewKpiBox, IFireOverviewParams, IPerimeterLocation, IClient, IFireOverviewFireInfo, IPropertyInFire, IVisit, IVisitResult } from "../../api/models";
import { getKpiBoxViewModel, getPreviousPerimeterByFire, getPerimeterByFire, getZipCode, getFireInformation, initPropertyQuery, getVisitsByPropertyId, IVisitParam, getPerimeterById, IThreatenedParam, getThreatenedMessage, getValidClientIds, getVisits } from "../../api/fireOverviewAPI";
import * as atlas from "azure-maps-control";
import { Constants } from "utils/constants";

/** Slice for Fire Overview */
import {
    OneMileBuffer,
    ThreeMileBuffer,
    AlertDistance,
    HalfMileBuffer,
    FirePerimeter,
    PreviousFires,
    ResEnrolled,
    ResNotEnrolled,
    ThreatPerimeter,
    ResOther,
    CommEnrolled,
    CommNotEnrolled,
    CommOther,
    AgriEnrolled,
    AgriNotEnrolled,
    AgriOther,
    MguEnrolled
} from "../../assets/img";

//define the state for Fire Overview
export interface FireOverviewState {
    accessMessage: string;
    fireId: number;
    clientId: number;
    isArchive: boolean;
    propertyId: number;
    visitId: number;
    curTabName: string;
    fireOverviewKpiBox: IFireOverviewKpiBox;
    showPropertyDetail: boolean;
    legendItems: { text: string, imgSrc?: string, color?: string }[];
    previousFirePerimeters?: IPerimeterLocation[];
    wdsFirePerimeter?: atlas.data.FeatureCollection;
    zipCodes?: atlas.data.FeatureCollection;
    selectedProperty: {};
    selectedLayerIds?: string[];
    policyholders?: IPropertyInFire[];
    highLightPolicyholder?: IPropertyInFire;
    clientsByFire?: IClient[];
    clientIdsByFire?: number[];
    visits?: IVisit[];
    threatenedMessage?: string[];
    fireInformation: IFireOverviewFireInfo;
    resetAllTriggered: boolean;
    callLogVisit: IVisitResult;
}

const initialState: FireOverviewState = {
    accessMessage: '',
    clientId: 0,
    fireId: 0,
    isArchive: false,
    propertyId: 0,
    visitId: 0,
    curTabName: Constants.FireOverviewTabsText.FireInformation,
    legendItems: [
        { text: "Fire Perimeter", imgSrc: FirePerimeter },
        { text: "Threat Perimeter", imgSrc: ThreatPerimeter },
        { text: ".5 Mile Buffer", imgSrc: HalfMileBuffer },
        { text: "1 Mile Buffer", imgSrc: OneMileBuffer },
        { text: "3 Mile Buffer", imgSrc: ThreeMileBuffer },
        { text: "Alert Distance", imgSrc: AlertDistance },
        { text: "Previous Fires", imgSrc: PreviousFires },
        { text: "Res. Enrolled", imgSrc: ResEnrolled },
        { text: "Res. Not Enrolled", imgSrc: ResNotEnrolled },
        { text: "Res. Other", imgSrc: ResOther },
        { text: "Comm. Enrolled", imgSrc: CommEnrolled },
        { text: "Comm. Not Enrolled", imgSrc: CommNotEnrolled },
        { text: "Comm. Other", imgSrc: CommOther },
        { text: "Agri. Enrolled", imgSrc: AgriEnrolled },
        { text: "Agri. Not Enrolled", imgSrc: AgriNotEnrolled },
        { text: "Agri. Other", imgSrc: AgriOther },
        { text: "MGU", imgSrc: MguEnrolled },
    ],
    fireOverviewKpiBox: {},
    showPropertyDetail: false,
    wdsFirePerimeter: undefined,
    previousFirePerimeters: [],
    zipCodes: undefined,
    visits: [],
    threatenedMessage: [],
    selectedProperty: {},
    policyholders: [],
    highLightPolicyholder: undefined,
    fireInformation: {},
    resetAllTriggered: false,
    callLogVisit: {dataset: [], totalCount: 0}
}

export const fetchOverviewKpiBox = createAsyncThunk('actions/fetchOverviewKpiBox', async (params: IFireOverviewParams) => {
    let response = await getKpiBoxViewModel(params);
    return response || {};
});

export const fetchPreviousPerimeterByFire = createAsyncThunk('actions/fetchPreviousPerimeterByFire', async (fireId: number) => {
    let response = await getPreviousPerimeterByFire(fireId);
    return response || {};
});

export const fetchPerimeterByFire = createAsyncThunk('actions/fetchPerimeterByFire', async (fireId: number) => {
    let response = await getPerimeterByFire(fireId);
    return response || {};
});

export const fetchPerimeterById = createAsyncThunk('actions/fetchPerimeterById', async (id: number) => {
    let response = await getPerimeterById(id);
    return response || {};
});

export const fetchZipCode = createAsyncThunk('actions/fetchZipCode', async (params: IFireOverviewParams) => {
    let filter = {
        Filter: {
            FireId: params.fireId,
            ClientId: params.clientId
        }
    }
    let response = await getZipCode(filter);
    return response || {};
});

export const fetchFireInformation = createAsyncThunk('actions/fetchFireInformation', async (params: IFireOverviewParams) => {
    let response = await getFireInformation(params);
    return response || {};
});

export const postInitPropertyQuery = createAsyncThunk("actions/initPropertyQuery", async () => {
    let response = await initPropertyQuery();
    return response || [];
});

export const fetchVisitsByFilter = createAsyncThunk("actions/fetchVisitsByFilter", async (param: IVisitParam) => {
    let response = await getVisitsByPropertyId(param);
    return response || [];
});

export const fetchThreatenedMessage = createAsyncThunk("actions/fetchThreatenedMessage", async (param: IThreatenedParam) => {
    let response = await getThreatenedMessage(param);
    return response || [];
});

export const fetchValidClientIds = createAsyncThunk("actions/fetchValidClientIds", async (param: number) => {
    let response = await getValidClientIds(param);
    return response || [];
});

export const getMostRecentVisit = createAsyncThunk("actions/getMostRecentVisit", async (param: IVisitParam) => {
    let response = await getVisits(param);
    return response || null;
});

/** State slice with reducers, link reducer and action */
export const fireOverviewSlice = createSlice({
    name: "fireOverview",
    initialState,
    reducers: {
        setAccessMessage: (state, action) => {
            state.accessMessage = action.payload;
        },
        setFireId: (state, action) => {
            state.fireId = action.payload;
        },
        setClientId: (state, action) => {
            state.clientId = action.payload;
        },
        setPropertyId: (state, action) => {
            state.propertyId = action.payload;
        },
        setVisitId: (state, action) => {
            state.visitId = action.payload;
        },
        setTabName: (state, action) => {
            state.curTabName = action.payload;
        },
        setShowPropertyDetail: (state, action) => {
            state.showPropertyDetail = action.payload;
        },
        setPolicyholders: (state, action) => {
            state.policyholders = action.payload;
        },
        selectPolicyHolder: (state, action) => {
            if (state.policyholders?.length) {
                state.policyholders.forEach(ph => {
                    if (ph.propertyId === action.payload) {

                        ph.isTop = true;
                        state.highLightPolicyholder = { ...ph };
                    }
                    else {
                        ph.isTop = false;
                    }
                });
            }
        },
        setClientsByFire: (state, action) => {
            state.clientsByFire = action.payload;
        },
        setSelectedProperty: (state, action) => {
            state.selectedProperty = state.policyholders?.find((item) => {
                return item.propertyId == action.payload.pid;
            }) ?? {}
        },
        setSelectedLayerIds: (state, action) => {
            state.selectedLayerIds = action.payload;
        },
        setResetAllTriggered: (state, action) => {
            state.resetAllTriggered = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchOverviewKpiBox.fulfilled, (state, action) => {
                state.fireOverviewKpiBox = action.payload;
            })
            .addCase(fetchPreviousPerimeterByFire.fulfilled, (state, action) => {
                state.previousFirePerimeters = action.payload;
            })
            .addCase(fetchPerimeterByFire.fulfilled, (state, action) => {
                state.wdsFirePerimeter = action.payload as atlas.data.FeatureCollection;
            })
            .addCase(fetchPerimeterById.fulfilled, (state, action) => {
                state.wdsFirePerimeter = action.payload as atlas.data.FeatureCollection;
            })
            .addCase(fetchZipCode.fulfilled, (state, action) => {
                state.zipCodes = action.payload as atlas.data.FeatureCollection;
            })
            .addCase(fetchThreatenedMessage.fulfilled, (state, action) => {
                state.threatenedMessage = action.payload;
            })
            .addCase(fetchVisitsByFilter.fulfilled, (state, action) => {
                state.visits = action.payload;
            })
            .addCase(fetchFireInformation.fulfilled, (state, action) => {
                const fireInformation = action.payload;
                state.fireInformation = fireInformation;
                state.isArchive = fireInformation.archived === true;
            })
            .addCase(fetchValidClientIds.fulfilled, (state, action) => {
                state.clientIdsByFire = action.payload;
            })
            .addCase(getMostRecentVisit.fulfilled, (state, action) => {
                state.callLogVisit = action.payload;
            })
    }
});

/** Action creators are generated for each case reducer function */
export const {
    setAccessMessage,
    setFireId,
    setClientId,
    setPropertyId,
    setVisitId,
    setTabName,
    setShowPropertyDetail,
    setPolicyholders,
    selectPolicyHolder,
    setClientsByFire,
    setSelectedProperty,
    setSelectedLayerIds,
    setResetAllTriggered,
} = fireOverviewSlice.actions;

export default fireOverviewSlice.reducer;

