import * as atlas from "azure-maps-control";

export const getArea = (geometry: any, blAcres?: boolean) => {
    if (!geometry) return ''
    const area = atlas.math.getArea(geometry, blAcres ? atlas.math.AreaUnits.acres : atlas.math.AreaUnits.squareMiles, 4);
    let areaValue = "";
    if (area > 1) {
        areaValue = area.toFixed(1).toLocaleString();
    }
    else if (area > 0.01) {
        areaValue = area.toFixed(2).toString();
    }
    else if (area > 0.0001) {
        areaValue = area.toFixed(4).toString();
    }
    else {
        areaValue = "0.0001";
    }
    return areaValue;
}