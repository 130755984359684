import { postJson } from "../utils/index";
import { IPastRiskScores } from "api/models";

export async function getPastRiskScores(
  client_ids: number[],
  type: number,
  start: Date,
  end: Date
): Promise<IPastRiskScores[]> {
  return postJson("/api/risk/risk/getpastriskscores", {
    clientId: client_ids,
    type: type,
    start: start,
    end: end,
  }).then((res) => res);
}

export async function getRiskUrl(): Promise<string> {
  return postJson("/api/risk/risk/GetRiskUrl", "");
}
