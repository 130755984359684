import { createSlice } from "@reduxjs/toolkit"
import { IApiUserInfo } from "api/models"

interface IApiManagementState {
    currentApiUser?: IApiUserInfo,
    refreshUserList?: boolean,
    blEditor?: boolean,
    tokenPage?: boolean,
}
const initialState: IApiManagementState = {
}
const apiManagementActions = createSlice({
    name: 'apiManagementActions',
    initialState,
    reducers: {
        setCurrentApiUser: (state, action) => {
            state.currentApiUser = action.payload;
        },
        setRefreshUserList: (state, action) => {
            state.refreshUserList = action.payload;
        },
        setBlEditor: (state, action) => {
            state.blEditor = action.payload;
        },
        setTokenPage: (state, action) => {
            state.tokenPage = action.payload;
        },
    }
})
export const {
    setCurrentApiUser,
    setRefreshUserList,
    setBlEditor,
    setTokenPage,
} = apiManagementActions.actions;

export default apiManagementActions.reducer;