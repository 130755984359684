import { IMemberInfo, IAnnouncement, IAnnouncementCurrentMetrics, IAnnouncementPreviousMetrics, INoteworthyFire, INoteworthyFireParams, IFireReviewedModel, IDailyOperation, IMonitoringRegions, IFireBoardClientsNoticeType, IFireBoardUpdateParams, IMyStates } from "./models";
import { getJson, postJson } from "utils";
import { ISevenDayTrendsMetrics } from "./models/workflowManagement/ISevenDayTrendsMetrics";

/* Users */
export async function getMembersByGroupName(groupName:string): Promise<IMemberInfo[]> {
    var result = await getJson("/api/WorkflowManagement/AdGroup/GetMembersByGroupName?groupNameWithoutPrefix=" + groupName);
    return result ?? [];
}

export async function getCurrentUserId(): Promise<string> {
    var result = await getJson("/api/WorkflowManagement/AdGroup/GetCurrentUserId");
    return result ?? {};
}

/*Announcement Board*/
export async function getAnnouncement(): Promise<IAnnouncement> {
    var result = await getJson("/api/WorkflowManagement/Announcement/GetAnnouncement");
    return result ?? {};
}

export async function updateAnnouncement(announcement: IAnnouncement): Promise<string> {
    var result = await postJson("/api/WorkflowManagement/Announcement/UpdateAnnouncement", { ...announcement });
    return result ?? '';
}

export async function getSevenDayTrends(): Promise<ISevenDayTrendsMetrics[]> {
    var result = await getJson("/api/WorkflowManagement/Announcement/GetSevenDayTrends")
    return result ?? {};
}

export async function getUnpubPHAs(): Promise<number> {
    var result = await getJson("/api/WorkflowManagement/Announcement/GetUnpubPHAs")
    return result ?? {};
}

/* Region Map */
export async function getMap(): Promise<any> {
    return fetch("/states-split-california.json").then((r) => r.json());
}

export async function getMonitoringRegions(): Promise<IMonitoringRegions[]> {
    var result = await getJson("/api/WorkflowManagement/RegionMap/GetRegions");
    return result ?? [];
}

export async function getMyStates(): Promise<IMyStates[]> {
    var result = await getJson("/api/WorkflowManagement/RegionMap/GetStatesByCurrentUser");
    return result ?? [];
}

/* Daily Assignments */
export async function getDailyOperations(): Promise<IDailyOperation[]> {
    var result = await getJson("/api/WorkflowManagement/DailyAssignments/GetOperations");
    return result ?? [];
}

export async function updateDailyOperations(operation: IDailyOperation): Promise<boolean> {
    var result = await postJson("/api/WorkflowManagement/DailyAssignments/UpdateOperation", { ...operation });
    return result ?? false;
}

export async function deleteDailyOperations(iaIGuid: string): Promise<boolean> {
    var result = await postJson("/api/WorkflowManagement/DailyAssignments/DeleteOperation", iaIGuid);
    return result ?? false;
}
export async function getNoteworthyFires(): Promise<INoteworthyFire[]> {
    var result = await getJson("/api/WorkflowManagement/DailyAssignments/GetNoteworthyFires");
    return result ?? [];
}

export async function sendFireNoteworthyOff(param: any): Promise<INoteworthyFire[]> {
    var result = await postJson("/api/WorkflowManagement/DailyAssignments/SetFireNoteworthyOff", param);
    return result;
}

export async function sendFireReviewed(model: IFireReviewedModel): Promise<IFireReviewedModel> {
    var result = await postJson("/api/WorkflowManagement/DailyAssignments/SetFireReviewed", model);
    return result ?? { fireId: 0, reviewed: false };
}

export async function updateNoteworthyComments(param: INoteworthyFireParams): Promise<boolean> {
    var result = await postJson("/api/WorkflowManagement/DailyAssignments/UpdateNoteworthyComments", { ...param });
    return result ?? false;
}

export async function getFireBoard(): Promise<IFireBoardClientsNoticeType[]> {
    var result = await getJson("/api/WorkflowManagement/DailyAssignments/GetFireBoardItems");
    return result ?? [];
}

export async function updateFireBoardUsers(param: IFireBoardUpdateParams): Promise<boolean> {
    var result = await postJson("/api/WorkflowManagement/DailyAssignments/UpdateFireBoardItem", { ...param });
    return result ?? false;
}



