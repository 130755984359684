import { postJson } from "../utils/index";

export async function getGeogStates(params: any): Promise<any> {
  var result = await postJson(
      '/api/clientSubscription/clientSubscription/GetGeogStates',
      { ...params }
  );
  return result.data ?? {};
}
export async function getClients(params: any): Promise<any> {
  var result = await postJson(
      '/api/clientSubscription/clientSubscription/GetClients',
      { ...params }
  );
  return result.data ?? {};
}
// get map
export async function getMap(): Promise<any> {
  return fetch("/states-10m.json").then((r) => r.json());
}
// save client data
export async function saveClientData(params: any): Promise<any> {
  var result = await postJson(
    '/api/clientSubscription/clientSubscription/saveClientData',
    { ...params }
  );
  return result;
}