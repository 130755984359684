import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CS_TAB_SUBSCRIPTION } from "utils/consts";
import { IClientList } from "../../api/models";
import { getResClients } from "api/commonAPI";
import { getGeogStates, getClients, getMap, saveClientData } from "api/clientSubscriptionAPI"

interface IClientSubscription {
    tabIndex: number,
    clients: IClientList[],
    states: any,
    clientInfo?: IClientList,
    map?: any
  }
const initialState: IClientSubscription = {
  tabIndex: CS_TAB_SUBSCRIPTION,
  clients: [],
  states: []
}

// export const fetchClients = createAsyncThunk(
//   "clientSubscriptionActions/fetchClients",
//   async () => {
//       return getResClients({ filter: {} });
//   }
// );
export const fetchStates = createAsyncThunk("clientSubscriptionActions/fetchStates", async () => {
  let response = await getGeogStates({});
  return response || [];
});
export const fetchClients = createAsyncThunk("clientSubscriptionActions/fetchClients", async () => {
  let response = await getClients({});
  return response || [];
});
export const fetchMap = createAsyncThunk("actions/fetchMap", async () => {
  return getMap();
});
export const saveClientConfig = createAsyncThunk("clientSubscriptionActions/saveClientConfig", async (params: any) => {
  let response = await saveClientData(params);
  return response ?? 0;
});

const clientSubscriptionActions = createSlice({
    name: 'clientSubscriptionActions',
    initialState,
    reducers: {
      setClientInfo: (state, action) => {
        state.clientInfo = action.payload;
    },
      setTabIndex: (state, action) => {
        state.tabIndex = action.payload;
    },
    },
    extraReducers: (builder) => {
      builder
        //fetchClients
        // .addCase(fetchClients.fulfilled, (state, action) => {
        //     state.clients = action.payload;
        // })
        .addCase(fetchStates.fulfilled, (state, action) => {
            state.states = action.payload;
        })
        .addCase(fetchClients.fulfilled, (state, action) => {
            state.clients = action.payload;
        })
        .addCase(fetchMap.fulfilled, (state, action) => {
          state.map = action.payload;
        });
    },
});
export const {
  setClientInfo,
  setTabIndex
} = clientSubscriptionActions.actions

export default clientSubscriptionActions.reducer