/// <reference path="./index.d.ts"/>
import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";

import "./app.scss";
import App from "app/App";
import store from "app/store";
import * as serviceWorker from "./serviceWorker";
import { Header, Footer } from "features/global";
import { AuthProvider } from "components/common/auth/AuthProvider";

$(document)
  .ajaxSend(() => {
    window.requestCounter = (window.requestCounter || 0) + 1;
  })
  .ajaxComplete((e) => {
    window.requestCounter = (window.requestCounter || 0) - 1;
  });

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <CookiesProvider>
      <AuthProvider>
        <Provider store={store}>
          <Header />
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <Footer />
        </Provider>
      </AuthProvider>
    </CookiesProvider>
);

//CookiesProvider can be wrapped with <React.StrictMode>
// if the regular troubleshooting doesn't solve any problems

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
