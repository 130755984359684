import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PSP_TAB_GENERAL } from "utils/consts";
import atlas from "azure-maps-control";
import { IPropertyList, IPspSearchParam, IPagination, IPropertyAccess } from "../../api/models";
import { fetchCallList, fetchCallLogFires, getNotesSize, getPropertyAccess, getPropertyList } from "api/propertySearchAPI"

interface IPropertySearchState {
    searchPropertyParams?: IPspSearchParam,
    propertyInfo?: IPropertyList | any,
    propertyAccess?: IPropertyAccess | any,
    tabIndex: number,
    //Interact between Azure map and input  
    coordinateInput?: atlas.data.Position,  
    coordinateMap?: atlas.data.Position,
    newPropCoords?: atlas.data.Position,
    mapFullScreen?: boolean,
    enrollmentsSaved: number,
    callList: any[],
    callLogFires: [],
    noteSize: number,
    propertyPolicies?: IPropertyList[],
    activeIncidents?: any[]
}
const initialState: IPropertySearchState = {
    searchPropertyParams: {},
    tabIndex: PSP_TAB_GENERAL,
    mapFullScreen: false,
    enrollmentsSaved: 0,
    callList: [],
    callLogFires: [],
    noteSize: 0,
    propertyPolicies: [],
    activeIncidents: []
}
export const getCallList = createAsyncThunk('propertySearchActions/getCallList', async (inputs: any) => {
    let response = await fetchCallList(inputs);
    return response || [];
});
export const getCallLogFires = createAsyncThunk('propertySearchActions/getCallLogFires', async (inputs: any) => {
    let response = await fetchCallLogFires(inputs);
    return response || [];
});
export const getNoteLength = createAsyncThunk('propertySearchActions/getNotesLength', async () => {
    let response = await getNotesSize();
    return response || 0;
});
export const getPropertyPolicies = createAsyncThunk('propertySearchActions/getPropertyPolicies', async (inputs: any) => {
    let response = await getPropertyList(inputs);
    return response || [];
});

export const loadPropertyAccess = createAsyncThunk('propertySearchActions/getPropertyAccess', async (pid:number) => {
    let response = await getPropertyAccess(pid);
    return response || [];
})
const propertySearchActions = createSlice({
    name: 'propertySearchActions',
    initialState,
    reducers: {
        setSearchPropertyParams: (state, action) => {
            state.searchPropertyParams = action.payload;
        },
        setPropertyInfo: (state, action) => {
            state.propertyInfo = action.payload;
        },
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload;
        },
        setCoordinateInput: (state, action) => {
            state.coordinateInput = action.payload;
        },
        setCoordinateMap: (state, action) => {
            state.coordinateMap = action.payload;
        },
        setMapFullScreen: (state, action) => {
            state.mapFullScreen = action.payload;
        },
        setEnrollmentsSaved: (state, action) => {
            state.enrollmentsSaved = action.payload;
        },
        setNewPropCoords: (state, action) => {
            state.newPropCoords = action.payload;
        },
        setActiveIncidents: (state, action) => {
            state.activeIncidents = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getCallList.fulfilled, (state, action) => {
                state.callList = action.payload;
            })
            .addCase(getCallLogFires.fulfilled, (state, action) => {
                state.callLogFires = action.payload;
            })
            .addCase(getNoteLength.fulfilled, (state, action) =>{
                state.noteSize = action.payload;
            })
            .addCase(getPropertyPolicies.fulfilled, (state, action) =>{
                state.propertyPolicies = action.payload.data;
            })
            .addCase(loadPropertyAccess.fulfilled, (state, action) =>{
                state.propertyAccess = action.payload;
            })
        }
});
export const {
    setSearchPropertyParams,
    setPropertyInfo,
    setTabIndex,
    setCoordinateMap,
    setCoordinateInput,
    setMapFullScreen,
    setEnrollmentsSaved,
    setNewPropCoords,
    setActiveIncidents
} = propertySearchActions.actions

export default propertySearchActions.reducer