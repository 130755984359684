import { postJson } from "../utils/index";
import { IResClient } from "./models";

export interface INoticeConfigParam {
  clientIds: number[] | undefined;
}

export async function GetConfigs(data: any): Promise<any> {
  return postJson("/api/clientprofile/clientprofile/getconfigs", data).then((res) => res);
}

export async function GetStates(params: INoticeConfigParam): Promise<any> {
  return postJson("/api/clientprofile/clientprofile/getstates", {Filter: params}).then((res) => res?.data);
}

export async function GetClients(query: any): Promise<IResClient[]> {
  return postJson('/api/clients', {Filter: {Ids: query}}).then(res => res?.data || []);
}

export async function DeleteConfigs(query: any): Promise<any> {
  return await postJson('/api/clientprofile/clientprofile/deleteconfigs', query).then(res => res);
}

export async function GetClientStates(clientIds: any): Promise<any> {
  return await postJson('/api/clientprofile/clientprofile/getclientstates', { Filter: { clientIds }}).then(res => res?.data);
}

export async function SaveConfigs(query: any): Promise<any> {
  return await postJson("/api/clientprofile/clientprofile/saveconfigs", query).then((res) => res);
}
