import {
    getJson,
    postJson,
} from "../utils/index";

/* get policyholder list */
export async function getProperties(fireId: number): Promise<any> {
    return getJson("/api/workzones/workzones/Properties/" + fireId);
}

export async function getEnginesByFire(fireId: number): Promise<any> {
    return postJson("/api/workzones/workzones/getassignedengines", {
        Filter: { fireId },
    });
}

export async function getWorkZones(fireId: number): Promise<any> {
    return getJson("/api/workzones/workzones/GetWorkZones/" + fireId);
}

export async function updateWorkZones(payload:any): Promise<any> {

    let workZones: any[] = [];

    if (payload.features.length === 0) {
        if (payload.setId < 1)
            return null;

        let wz = {
            id: -1,//request for clear
            label: "",
            geog: null,
            engineItems: null,
            triggeredIds: null,
            workZoneSetId: payload.setId
        };
        workZones.push(wz);
    }
    else {
        for (let feature of payload.features) {
            let wz = {
                id: feature.properties.workZoneId ?? 0,
                label: feature.properties.label,
                geog: feature.geometry,
                engineItems: feature.properties.engineItems,
                triggeredIds: feature.properties.triggeredIds,
                workZoneSetId: 0
            };
            workZones.push(wz);
        }

        workZones[0].workZoneSetId = payload.setId;
    }
    
    return postJson("/api/workzones/workzones/UpdateWorkZones/" + payload.fireId, workZones);
}

