import { postJsonWithNoTrack, postJson, getJson } from "utils";
import { IResClient, IUSer, ITelemetry, IGeotabEngine, IGeoData } from "api/models";

// Telemetry
export async function writeLog(message: string, level: string) {
    return postJsonWithNoTrack('/api/log', { message, level });
}

export async function writeEvent(name: string) {
    return postJsonWithNoTrack('/api/Trace', { name });
}

export async function writeUserTrackingEvent(data: Partial<ITelemetry>) {
    return postJsonWithNoTrack('/api/Trace', data);
}

export async function writePageViewEvent(data: Partial<ITelemetry>) {
    return postJsonWithNoTrack('/api/Track', data);
}

// User

export async function getUserInfo(): Promise<IUSer> {
    return getJson('/me');
}

// Geotab
export async function getGeotabEngines(): Promise<IGeoData<IGeotabEngine>> {
    var result = await getJson(
        '/api/common/geotab/engines'
    );
    if (!result) result = {}
    return result;
}

// Clients
export async function getResClients(query: any): Promise<IResClient[]> {
    return postJson('/api/clients', query).then(res => res?.data || []);
}