import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAlliance, IEnginesKpiBox, IEnginesOnMap, ITaskForcePersons, ICenter, ILatitudeAndLongitude, ITaskForce, IEngineResource, IAssignment, IEnginesOnTable } from "../../api/models";
import { getEnginesKpi, getEnginesOnMap, getTaskForcePersons, getAllCenters, saveTaskForce, saveCenter, getTaskForceById, getEngineResourceById, saveEngineResource, getAllTaskForces, getAssignmentsByEngineId, getCenterById, getLatitudeAndLongitude, getEnginesOnTable } from "../../api/resourceStatusAPI";

//define the state for Resource Status
export interface ResourceStatusState {
    kpi: IEnginesKpiBox,
    enginesOnMap: IEnginesOnMap[],
    enginesOnTable: IEnginesOnTable[],
    filteredEnginesOnMap: IEnginesOnMap[],
    selectedLayerIds: string[],
    selectedStates: string[],
    selectedTaskForces: number[],
    selectedCenters: number[],
    showEngineInfoSlideBar: boolean,
    highlightEngineId?: number,
    taskForcePersons: ITaskForcePersons,
    centers: ICenter[],
    taskForces: ITaskForce[],
    assignments: IAssignment[],
    //center    
    isSaved: boolean,
    centerId: number,
    centerInfo: IAlliance,
    isValid: boolean,
    isFormatValid: boolean,
    latitudeAndLongitude: ILatitudeAndLongitude,
    //taskForce
    isTSaved: boolean,
    taskForceId: number,
    taskForceInfo: ITaskForce,
    disabledBattalionSupervisor: boolean,
    //resource
    isAddEngineResource: boolean,
    isESaved: boolean,
    engineResourceId?: number,
    engineResourceInfo: IEngineResource,
    isEValid: boolean,
    disabledCenter: boolean,
    disabledTaskForce: boolean
}

const initialState: ResourceStatusState = {
    kpi: {},
    enginesOnMap: [],
    enginesOnTable: [],
    filteredEnginesOnMap: [],
    selectedLayerIds: [],
    selectedStates: [],
    selectedTaskForces: [],
    selectedCenters: [],
    showEngineInfoSlideBar: false,
    highlightEngineId: undefined,
    taskForcePersons: {},
    centers: [],
    taskForces: [],
    assignments: [],
    //center    
    isSaved: false,
    centerId: 0,
    centerInfo: {},
    isValid: true,
    isFormatValid: true,
    latitudeAndLongitude: {},
    //taskForce  
    isTSaved: false,
    taskForceId: 0,
    taskForceInfo: {},
    disabledBattalionSupervisor: false,
    //resource
    isAddEngineResource: true,
    isESaved: false,
    engineResourceId: undefined,
    engineResourceInfo: {},
    isEValid: true,
    disabledCenter: true,
    disabledTaskForce: true
}

/* KPI */
export const fetchEnginesKpi = createAsyncThunk('actions/fetchEnginesKpi', async () => {
    let response = await getEnginesKpi();
    return response || {};
});

/* Map */
export const fetchAssignmentsByEngineId = createAsyncThunk('actions/fetchAssignmentsByEngineId', async (engineId: number) => {
    let response = await getAssignmentsByEngineId(engineId);
    return response || [];
});

export const fetchEnginesOnMap = createAsyncThunk('actions/fetchEnginesOnMap', async () => {
    let response = await getEnginesOnMap();
    return response || [];
});

export const fetchEnginesOnTable = createAsyncThunk('actions/fetchEnginesOnTable', async () => {
    let response = await getEnginesOnTable();
    return response || [];
});

/* Center */
export const saveCenterAction = createAsyncThunk('actions/saveCenterAction', async (allianceViewModel: any) => {
    let response = await saveCenter(allianceViewModel);
    return response || false;
});

export const fetchAllCenters = createAsyncThunk('actions/fetchAllCenters', async () => {
    let response = await getAllCenters();
    return response || [];
});

export const fetchCenterInfo = createAsyncThunk('actions/fetchCenterInfo', async (centerId: number) => {
    let response = await getCenterById(centerId);
    return response || {};
});

export const fetchLatitudeAndLongitude = createAsyncThunk('actions/fetchLatitudeAndLongitude', async (allianceViewModel: any) => {
    let response = await getLatitudeAndLongitude(allianceViewModel);
    return response || {};
});

/* Task Force */
export const fetchTaskForcePersons = createAsyncThunk('actions/fetchTaskForcePersons', async () => {
    let response = await getTaskForcePersons();
    return response || {};
});

export const fetchAllTaskForces = createAsyncThunk('actions/fetchAllTaskForces', async () => {
    let response = await getAllTaskForces();
    return response || [];
});

export const fetchTaskForceInfo = createAsyncThunk('actions/fetchTaskForceInfo', async (id: number) => {
    let response = await getTaskForceById(id);
    return response || {};
});

export const saveTaskForceAction = createAsyncThunk('actions/saveTaskForceAction', async (taskForceViewModel: any, { dispatch }) => {
    let response = await saveTaskForce(taskForceViewModel);    
    return response || false;
});

/* Resource */
export const fetchEngineResourceInfo = createAsyncThunk('actions/fetchEngineResourceInfo', async (id: number) => {
    let response = await getEngineResourceById(id);
    return response || {};
});

export const fetchSaveEngineResource = createAsyncThunk('actions/fetchSaveEngineResource', async (engineResourceViewModel: any, { dispatch }) => {
    let response = await saveEngineResource(engineResourceViewModel);
    dispatch(fetchEnginesOnMap());
    dispatch(fetchEnginesOnTable());
    return response || false;
});

/** State slice with reducers, link reducer and action */
export const resourceStatusSlice = createSlice({
    name: "resourceStatus",
    initialState,
    reducers: {
        setSelectedLayerIds: (state, action) => {
            state.selectedLayerIds = action.payload;
        },
        setSelectedStates: (state, action) => {
            state.selectedStates = action.payload;
        },
        setSelectedTaskForces: (state, action) => {
            state.selectedTaskForces = action.payload;
        },
        setSelectedCenters: (state, action) => {
            state.selectedCenters = action.payload;
        },
        setShowEngineInfoSlideBar: (state, action) => {
            state.showEngineInfoSlideBar = action.payload;
        },
        setHighlightEngineId: (state, action) => {
            state.highlightEngineId = action.payload;
        },
        setFilteredEnginesOnMap: (state, action) => {
            state.filteredEnginesOnMap = action.payload;
        },
        setCenterId: (state, action) => {
            state.centerId = action.payload;
        },
        setTaskForceId: (state, action) => {
            state.taskForceId = action.payload;
        },
        setCenterInfo: (state, action) => {
            state.centerInfo = action.payload as IAlliance;
        },
        setTaskForceInfo: (state, action) => {
            state.taskForceInfo = action.payload as ITaskForce;
        },
        setIsValid: (state, action) => {
            state.isValid = action.payload;
        },
        setIsFormat: (state, action) => {
            state.isFormatValid = action.payload;
        },
        setDisabledBattalionSupervisor: (state, action) => {
            state.disabledBattalionSupervisor = action.payload;
        },
        setLatitudeAndLongitude: (state, action) => {
            state.latitudeAndLongitude = action.payload;
        },
        setEngineResourceInfo: (state, action) => {
            state.engineResourceInfo = action.payload as IEngineResource;
        },
        setIsEValid: (state, action) => {
            state.isEValid = action.payload;
        },
        setIsAddEngineResource: (state, action) => {
            state.isAddEngineResource = action.payload;
        },
        setDisabledCenter: (state, action) => {
            state.disabledCenter = action.payload;
        },
        setDisabledTaskForce: (state, action) => {
            state.disabledTaskForce = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchEnginesKpi.fulfilled, (state, action) => {
                state.kpi = action.payload;
            })
            .addCase(fetchAssignmentsByEngineId.fulfilled, (state, action) => {
                state.assignments = action.payload;
            })
            .addCase(fetchEnginesOnMap.fulfilled, (state, action) => {
                state.enginesOnMap = action.payload;
            })
            .addCase(fetchEnginesOnTable.fulfilled, (state, action) => {
                state.enginesOnTable = action.payload;
            })
            .addCase(fetchTaskForcePersons.fulfilled, (state, action) => {
                state.taskForcePersons = action.payload;
            })
            .addCase(fetchAllCenters.fulfilled, (state, action) => {
                state.centers = action.payload;
            })
            .addCase(fetchAllTaskForces.fulfilled, (state, action) => {
                state.taskForces = action.payload;
            })
            .addCase(fetchCenterInfo.fulfilled, (state, action) => {
                state.centerInfo = action.payload;
            })
            .addCase(fetchTaskForceInfo.fulfilled, (state, action) => {
                state.taskForceInfo = action.payload;
            })
            .addCase(fetchEngineResourceInfo.fulfilled, (state, action) => {
                state.engineResourceInfo = action.payload;
            })
            .addCase(fetchSaveEngineResource.fulfilled, (state, action) => {
                state.engineResourceInfo = {};
                state.engineResourceId = undefined;
            })
    }
});

/** Action creators are generated for each case reducer function */
export const {
    setSelectedLayerIds,
    setSelectedStates,
    setSelectedTaskForces,
    setSelectedCenters,
    setShowEngineInfoSlideBar,
    setHighlightEngineId,
    setFilteredEnginesOnMap,
    setLatitudeAndLongitude,
    setCenterInfo,
    setCenterId,
    setIsValid,
    setIsFormat,
    setTaskForceInfo,
    setTaskForceId,
    setEngineResourceInfo,
    setIsEValid,
    setIsAddEngineResource,
    setDisabledCenter,
    setDisabledTaskForce
} = resourceStatusSlice.actions;

export default resourceStatusSlice.reducer;