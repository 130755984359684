import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as atlas from "azure-maps-control";
import { getPerimeterByFire, getPerimeterById } from "../../../api/notificationsAPI";

// This slice is for getting perimeter for fire or notice

export const fetchPerimeterByFire = createAsyncThunk('actions/fetchPerimeterByFire', async (fireId: number) => {
    let response = await getPerimeterByFire(fireId);
    return response || {};
});

export const fetchPerimeterById = createAsyncThunk('actions/fetchPerimeterById', async (id: number) => {
    let response = await getPerimeterById(id);
    return response || {};
});

interface ISnapshotState {
    wdsFirePerimeter?: atlas.data.FeatureCollection;
    mapReady: boolean;
    layerRenderReady: boolean;
    layerDataReady: boolean;
    mapReady2: boolean;
    layerRenderReady2: boolean;
    layerDataReady2: boolean;
    lockSwitch: number; // always be the min index of notice which no generated snapshot and also make sure generate one by one.
    mapStable: boolean;
}

const initialState: ISnapshotState = {
    wdsFirePerimeter: undefined,
    mapReady: false,
    layerRenderReady: false,
    layerDataReady: false,
    mapReady2: false,
    layerRenderReady2: false,
    layerDataReady2: false,
    lockSwitch: 0,
    mapStable: false,
}

export const snapshotSlice = createSlice({
    name: "mapSnapshot",
    initialState,
    reducers: {
        setMapReady: (state, action) => {
            state.mapReady = action.payload;
        },
        setLayerRenderReady: (state, action) => {
            state.layerRenderReady = action.payload;
        },
        setLayerDataReady: (state, action) => {
            state.layerDataReady = action.payload;
        },
        setMapReady2: (state, action) => {
            state.mapReady2 = action.payload;
        },
        setLayerRenderReady2: (state, action) => {
            state.layerRenderReady2 = action.payload;
        },
        setLayerDataReady2: (state, action) => {
            state.layerDataReady2 = action.payload;
        },
        setLockSwitch: (state, action) => {
            state.lockSwitch = action.payload;
        },
        setMapStable: (state, action) => {
            state.mapStable = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPerimeterByFire.fulfilled, (state, action) => {
            state.wdsFirePerimeter = action.payload as atlas.data.FeatureCollection;
        })
            .addCase(fetchPerimeterById.fulfilled, (state, action) => {
                state.wdsFirePerimeter = action.payload as atlas.data.FeatureCollection;
            })
    }
});

export const {
    setMapReady,
    setLayerRenderReady,
    setLayerDataReady,
    setMapReady2,
    setLayerRenderReady2,
    setLayerDataReady2,
    setLockSwitch,
    setMapStable
} = snapshotSlice.actions;

export default snapshotSlice.reducer;