import { ILayerItem } from "api/models";

export const generateTimelineItems = (data: any) => {
  const thisYear = new Date().getFullYear();
  const timeFrames = [];
  for (var i = 2013; i <= thisYear; i++) {
    timeFrames.push({
      year: i.toString(),
      nonDispatched: 0,
      demobilized: 0,
    });
  }
  for (const d of data) {
    const { year, status, count } = d;
    if (year >= 2013) {
      const frame = timeFrames[year - 2013];
      if (status === 2) {
        frame.nonDispatched = count;
      } else if (status === 3) {
        frame.demobilized = count;
      }
    }
  }
  timeFrames.reverse();
  return timeFrames
    .filter((s) => s.demobilized || s.nonDispatched)
    .map((s) => {
      const { year, nonDispatched, demobilized } = s;
      return {
        text: year,
        value: year,
        data: {
          nonDispatched,
          demobilized,
        },
      } as ILayerItem;
    });
};

export const formatFireSize = (size: string): string => {
  if (size === "-1") {
    return "0";
  }
  return size || "n/a";
};
