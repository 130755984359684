import { IPHVisit, IPHVisitDetail, IActionType, IActionTypesMap, IResFire, IResFireState, IResClient } from "api/models";
import { getJson, groupBy, postJson } from "utils";

export async function getPHVisits(query: any): Promise<Array<IPHVisit>> {
    return postJson('api/pha/visits', query).then(res => res.data || []);
}

export async function getVisitsStatuses(query: any) {
    return postJson('api/pha/visits/status', query);
}

export async function getPHVisitDetails(id: number): Promise<IPHVisitDetail> {
    return getJson(`api/pha/visits/details/${id}`);
}

export async function saveVisitDetail(detail: Partial<IPHVisitDetail>): Promise<void> {
    return postJson(`api/pha/visits/details`, detail);
}

export async function getActionTypes(): Promise<IActionTypesMap> {
    return postJson('api/pha/common/ActionTypes', {}).then(res => {
        return groupBy<IActionType, 'category'>(res.data || [], 'category');
    });
}

export async function getHomeStatusOptions(): Promise<string[]> {
    return postJson('api/pha/visits/homestatus', {}).then(res => res.data || []);
}

export async function getResFires(): Promise<IResFire[]> {
    return postJson('api/pha/visits/fires', {});
}

export async function getResFireStates(): Promise<IResFireState[]> {
    return postJson('api/states', {});
}

export async function getResClients(query: any): Promise<IResClient[]> {
    return postJson('api/clients', query);
}

export async function getPhotosPath(blobPath: string, blobThumbPath: string) {
    return postJson('api/PHA/Visits/PhotoToken', { blobPath, blobThumbPath });
}

export async function getBlobSASUrl(blobPath: string, blobThumbPath: string): Promise<any> {
    return postJson('/api/pha/visits/uploadnewphotos', { blobPath, blobThumbPath });
}

export async function saveUploadedPhotos(newPhotos: any, visitId: number): Promise<any> {
    return await postJson('/api/pha/visits/saveuploadedphotos', { newPhotos, visitId });
}

export async function getFireNames(clientId: number): Promise<any> {
    return postJson('/api/pha/visits/getfirenames', clientId);
}

export async function getPropertyAddresses(data: any): Promise<any> {
    return postJson('/api/pha/visits/getpropertyaddresses', data);
}

export async function getEngines(data: any): Promise<any> {
    return postJson('/api/pha/visits/getengines', data).then(res => res.data || []);
}

export async function saveNewPHA(data: any): Promise<any> {
    const saved = await postJson('/api/pha/visits/savenewpha', data).then(res => res || 0);
    return saved;
}

export async function getOverlappingVisit(data: any): Promise<any> {
    const visit = await postJson('/api/pha/visits/getoverlappingvisit', data).then(res => res || []);
    return visit;
}