import {
  IFireDetailSmokeCheck,
  IFirePerimeter,
  IScAttachFile,
  ISmokeCheckMessage,
  IPerimeterLocation,
  ISCPropertyQuery,
  IPropertyCity,
  ILob,
  IPropertyInFire
} from "./models";
import { getJSON } from "jquery";
import { getJson, postJson, postJsonWithNoTrack, downloadKMZ } from "../utils/index";

/*smoke check page properties table*/
export async function GetMonitorTriggers(fireId: any) {
  return postJson("/api/smokecheck/fires/MonitorTriggers", { Filter: { fireId } }).then((res) => res?.data);
}

/*fire datatbale --begin*/
export interface ISortField {
  Name: string;
  IsDesc: boolean;
}
export interface IFireDataTableFilter {
  pagination?: {
    pageSize: number;
    pageNumber: number;
  };
  Filter: {
    FireId: number;
    ClientIds: number[];
    WdsLob?: number;
    Address?: string;
    AlertBuffer?: any;
    ThreatBuffer?: any;
  };
  Sorting?: ISortField[];
}
export interface IFireDataTable {
  policyId: string;
  client: string;
  clientId: number;
  address: string;
  city: string;
  state: string;
  zip: string;
  threatened: any;
  distance: any;
  geocode: string;
  enrolled: any;
  numberOfBuilding: number;
  prePlan: string;
  pid: number;
  isFull: boolean;
  rowTop: number;
  rowHeight: number;
  wdsLob: number;
  selected: boolean;
  isTop: boolean;
  mapNotEnrolledColor?: string;
  mapEnrolledColor?: string;
  agencyCode?: string;
  status: string;
  rehabRequired: boolean;
}
export interface IDownloadKmz {
  PerimeterId?: number;
  ThreatPerimeterId?: number;
  Miles: number;
  FireName?: string;
  ObsId?: string;
  FireId?: number;
}
export interface ITeamsChatMessage {
  Content: string,
  Mentions?: IMetionUser[],
}
export interface IMetionUser {
  UserId: string,
  DisplayName: string,
}

export async function downloadSmokecheckKmz(params: IDownloadKmz) {

  const url = '/api/smokecheck/fires/ConstructSmokeCheckKML';

  downloadKMZ(url, params);

}

export async function getPolicyholders(params: IFireDataTableFilter): Promise<IFireDataTable[]> {
  const { Filter } = params;
  var result = await postJson("/api/smokecheck/fires/getproperties", {
    Filter: Filter,
  });
  return result?.data;
}
/*fire datatbale --end*/

/*firemap get fire perimeter --begin*/
export async function getPerimeterByFire(id: number): Promise<IFirePerimeter> {
  var result = await getJSON("/api/smokecheck/fires/getPerimeterByFire/" + id);
  return result ?? {};
}
/*firemap get fire perimeter --end*/

/*previous fire --begin*/
export async function getPreviousPerimeterByFire(id: number): Promise<IPerimeterLocation> {
  var result = await getJSON("/api/smokecheck/fires/GetPreviousPerimeterByFire/" + id);
  return result ?? {};
}
/*previous get fire perimeter --end*/

export async function getFireDetail(id: number): Promise<IFireDetailSmokeCheck> {
  var result = await getJSON("/api/smokecheck/fires/getfiredetails/" + id);
  return result ?? {};
}

/**/
export async function GetBlobSASUrl(params: IScAttachFile) {
  return postJson('/api/smokecheck/fires/GetBlobSASUrl', { ...params });
}
/*delete file from storage*/
export async function DelBlobAsync(params: IScAttachFile) {
  return postJson('/api/smokecheck/fires/DeleteBlob', { ...params });
}
/*get all internal users */
export async function GetTagUsersAsync(params: any) {
  return postJson('/api/smokecheck/fires/GetTagUsers', { ...params });
}
/*get current user */
export async function GetMeAsync() {
  return postJson('/api/smokecheck/fires/GetMe', {});
}
/*save message */
export async function SaveMessageAsync(model: ISmokeCheckMessage) {
  return postJson('/api/smokecheck/fires/SaveMessage', { ...model });
}
/*get message list */
export async function GetMessageAsync(fireId: number, messageId?: number) {
  return postJsonWithNoTrack('/api/smokecheck/fires/GetMessage', { Filter: { fireId, messageId } })
    .then(res => res.json())
}

export async function getEnginesByFire(fireId: number): Promise<any> {
  return postJson('/api/smokecheck/fires/getenginesbyfire', { Filter: { fireId } }).then(res => res?.data);
}

/*Post message to Teams channel */
/*#24319 [Smoke Check][CR] Send Smoke Check Messages to Teams Chat Channels */
export async function postMessageToTeamsAsync(message: ITeamsChatMessage) {
  return postJsonWithNoTrack('/api/SmokeCheck/Teams/SendNotification', message);
}

/** Property Queries Start */
export async function initPropertyQuery(): Promise<any> {
  return postJson("/api/FireOverview/FireOverview/InitDbWithPropertyQuery", {});
}

export interface IPropertyQueryParam {
  fireId: number;
  clientIds?: any;
}

export async function getPropertyQueries(param: IPropertyQueryParam): Promise<ISCPropertyQuery[]> {
  return postJson("/api/smokecheck/fires/GetPropertyQueries", { Filter: { fireId: param.fireId } })
      .then(result => result?.data?.data);
}

export async function savePropertyQuery(param: ISCPropertyQuery): Promise<number> {
  return postJson("/api/FireOverview/FireOverview/SavePropertyQuery", param)
      .then(result => result?.data);
}

export async function deletePropertyQuery(key: number): Promise<number> {
  return postJson("/api/FireOverview/FireOverview/DeletePropertyQuery", key)
      .then(result => result?.data);
}

export interface IPropertyCityParam {
  fireId: number;
  clientIds: number[] | undefined;
}
export async function getPropertyCities(param: IPropertyCityParam): Promise<IPropertyCity[]> {
  return postJson("/api/smokecheck/fires/GetPropertyCities", { Filter: { fireId: param.fireId, clientIds: param.clientIds } })
      .then(result => result?.data?.data);
}

export async function getAllLob(): Promise<ILob[]> {
  return getJson("/api/FireOverview/FireOverview/GetAllLob")
      .then(result => result?.data);
}

export interface IPropertyInFireParam {
  fireId: number;
  clientIds: any;
  cities?: string;
  threatened?: boolean;
  lastPropertyStatus?: string;
  propertyTypes?: string;
  fireName?: string;
  perimeterId?: number;
  threatBuffer?: any;
  alertBuffer?: any;
}

export async function getPropertyInFire(param: IPropertyInFireParam): Promise<IPropertyInFire[]> {
  return postJson("/api/smokecheck/fires/GetPropertyInFire", {Filter: param})
      .then(result => result?.data);
}

/** Property Queries End */

export async function getFireHistory(fireId: number): Promise<any> {
  return postJson('/api/firemap/fires/getfirehistory/', { Filter: { fireId } }).then(res => res?.data);
}

export async function getResourceOrders(fireId: number): Promise<any> {
  return postJson('/api/smokecheck/fires/getresourceorders/', fireId).then(res => res);
}
export async function sendDemobMessage(notification: any) {
  postJsonWithNoTrack('/api/SmokeCheck/Teams/SendDemobNotification', notification);
}