import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IVisit } from "../../../api/models";
import {
    getVisits,
    IVisitParam,
    getVisitDates,
    IFromToParam,
    getVisitsAll
} from "../../../api/fireOverviewAPI";

interface ISeeAllVisitsState {
    visits?: IVisit[];
    pageNumber: number;
    pageSize: number;
    visitDate?: string[];
    selectedDate: string;
    countInVisit: number;
    isToLoad: boolean;
    clearVisits: boolean;
}

const initialState: ISeeAllVisitsState = {
    pageNumber: 1,
    pageSize: 5,
    selectedDate: "",
    countInVisit: 0,
    isToLoad: true,
    clearVisits: false
}

export const getVisitsByFilter = createAsyncThunk("actions/getVisits", async (param: IVisitParam) => {
    let response = await getVisits(param);
    return response || null;
});

export const getVisitDatesAll = createAsyncThunk("actions/getVisitDates", async (param: IFromToParam) => {
    let response = await getVisitDates(param);
    return response || [];
});

export const getVisitsNoFilter = createAsyncThunk("actions/getVisitsNoFilter", async (param: IVisitParam) => {
    let response = await getVisitsAll(param);
    return response || null;
});

export const seeAllVisitsSlice = createSlice({
    name: "seeAllVisits",
    initialState,
    reducers: {
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload as number;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload as string;
        },
        setVisits: (state, action) => {
            state.visits = action.payload;
        },
        setClearVisits: (state, action) => {
            state.clearVisits = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getVisitsByFilter.fulfilled, (state, action) => {
                var res = action.payload;

                var tmp = state.visits;

                if (state.pageNumber == 1) {
                    state.visits = res?.dataset;
                }
                else {
                    state.visits = tmp?.concat(res?.dataset);
                }

                state.countInVisit = res?.totalCount;

                if (res?.totalCount == state.visits?.length) {
                    state.isToLoad = false;
                }
                else {
                    state.isToLoad = true;
                }
            })
            .addCase(getVisitDatesAll.fulfilled, (state, action) => {
                state.visitDate = action.payload;
            });
    }
});

export const {
    setPageNumber,
    setSelectedDate,
    setVisits,
    setClearVisits
} = seeAllVisitsSlice.actions;

export default seeAllVisitsSlice.reducer;