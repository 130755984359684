import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IEngineStatusDisplay } from "../../../api/models";
import {
    IExportEngineStatusParam,
    exportEngineStatusToExcel,
    IDisplayEngineStatusParam,
    displayEngineStatus,
} from "../../../api/resourceStatusAPI";

export const EnumWeekOrMonth = {
    MONTH: 1,
    WEEK: 2,
};

export const EnumSectionTitleStatus = {
    COLLAPSED: 1,
    FOLDED: 2,
};

export const EnumAllOrLess = {
    SHOWALL: 1,
    SEELESS: 2,
};

export const EnumPageNavigation = {
    GOFIRST: "1",
    GOPRE: "2",
    GONEXT: "3",
    GOLAST: "4",
};

export const EnumDataSort = {
    NONE: 0,
    ASC: 1,
    DESC: 2,
};

export const EngineSource = {
    WDS: 1,
    ALLIANCE: 2,
};

export const PAGESIZE: number = 10;

export const MONTHS: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const SHORT_MONTHS: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

export const WEEKS: number = 53;
export const CLASSOFHEADER = "rs-min-text-width";
export const CLASSOFHEADER2 = "rs-min-text-width2";
export const INITIALTOOLTIPID = "timeline-tooltip";
export const GROUP_BY_TASK_FORCE = "taskForceId";
export const GROUP_BY_CENTER = "centerId";

interface ITimelineState {
    collapseOrFold: number;

    headerText: string[];
    headerTextClass: string;

    dataInGrid: IEngineStatusDisplay[];
    topEngineId: number; // For map interaction

    statusTooltipId: string;

    // engines group by
    enginesGroupBy: string;

    // should show active engines only
    showActiveEnginesOnly: boolean;
    openCenter: boolean;
    openTaskForce: boolean;
    openResource: boolean;
}

const initialState: ITimelineState = {
    collapseOrFold: EnumSectionTitleStatus.COLLAPSED,

    headerText: MONTHS,
    headerTextClass: CLASSOFHEADER,

    dataInGrid: [],
    topEngineId: 0,

    statusTooltipId: INITIALTOOLTIPID,

    enginesGroupBy: GROUP_BY_TASK_FORCE,

    showActiveEnginesOnly: true,
    openCenter: false,
    openTaskForce: false,
    openResource: false,
};

/** API start */
export const apiExportEngineStatusToExcel = createAsyncThunk(
    "actions/apiExportEngineStatusToExcel",
    async (param: IExportEngineStatusParam) => {
        await exportEngineStatusToExcel(param);
    }
);

export const apiDisplayEngineStatus = createAsyncThunk(
    "actions/apiDisplayEngineStatus",
    async (param: IDisplayEngineStatusParam) => {
        let response = await displayEngineStatus(param);
        return response || null;
    }
);
/** API end */

export const timelineSlice = createSlice({
    name: "resourceTimeline",
    initialState,
    reducers: {
        setCollapseOrFold: (state, action) => {
            state.collapseOrFold = action.payload;
        },

        setHeaderText: (state, action) => {
            state.headerText = action.payload;
        },

        setEnginesGroupBy: (state, action) => {
            state.enginesGroupBy = action.payload;
        },

        setShowActiveEnginesOnly: (state, action) => {
            state.showActiveEnginesOnly = action.payload;
        },

        setHeaderTextClass: (state, action) => {
            state.headerTextClass = action.payload;
        },
        // For map interaction
        setTopEngineId: (state, action) => {
            state.topEngineId = action.payload;
        },
        setDataInGrid: (state, action) => {
            state.dataInGrid = action.payload;
        },
        setOpenCenter: (state, action) => {
            state.openCenter = action.payload;
        },
        setOpenTaskForce: (state, action) => {
            state.openTaskForce = action.payload;
        },
        setOpenResource: (state, action) => {
            state.openResource = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(apiDisplayEngineStatus.fulfilled, (state, action) => {
            let res = action.payload;
            state.dataInGrid = res.dataset ? res.dataset : [];
        });
    },
});

export const {
    setCollapseOrFold,
    setHeaderText,
    setHeaderTextClass,
    setTopEngineId,
    setDataInGrid,
    setOpenCenter,
    setOpenTaskForce,
    setOpenResource,
    setEnginesGroupBy,
    setShowActiveEnginesOnly,
} = timelineSlice.actions;

export default timelineSlice.reducer;
