import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Console } from "console";
import { IMemberInfo, IMonitoringRegions, IAnnouncement, IAnnouncementCurrentMetrics, IAnnouncementPreviousMetrics, INoteworthyFire, INoteworthyFireParams, IDailyOperation, IFireBoardClientsNoticeType, IFireBoardUpdateParams, IFireReviewedModel, IMyStates } from "../../api/models";
import { getMonitoringRegions, getMembersByGroupName, getAnnouncement, updateAnnouncement, getMap, getNoteworthyFires, updateNoteworthyComments, getDailyOperations, updateDailyOperations, deleteDailyOperations, getMyStates, getFireBoard, updateFireBoardUsers, getCurrentUserId, sendFireNoteworthyOff, sendFireReviewed, getSevenDayTrends, getUnpubPHAs } from "../../api/workflowManagementAPI";
import { Constants } from "../../utils/constants";
import { ISevenDayTrendsMetrics } from "api/models/workflowManagement/ISevenDayTrendsMetrics";

//define the state for Workflow Management
export interface WorkflowManagementState {
    currentUserId: string,
    membersOfDCA: IMemberInfo[],
    membersOfDutyOfficer: IMemberInfo[],
    announcement: IAnnouncement,
    announcementCurrentMetrics: IAnnouncementCurrentMetrics,
    announcementPreviousMetrics: IAnnouncementPreviousMetrics,
    map?: any,
    monitoringRegions: IMonitoringRegions[],
    myStates: IMyStates[],
    selectedRegionIds: number[],
    noteworthyFires: INoteworthyFire[] | any[],
    resSaveComments: boolean,
    dailyOperations: IDailyOperation[],
    
    fireBoardClientsNoticeType: IFireBoardClientsNoticeType[],
    overlayNoRowsTemplate: string,
    resultOfSaveFireBoard?: boolean,
    dialogParams:any,
    sevenDayTrends: ISevenDayTrendsMetrics[],
    unpubPHAs: number
}

const initialState: WorkflowManagementState = {
    currentUserId: '',
    membersOfDCA: [],
    membersOfDutyOfficer: [],
    announcement: {},
    announcementCurrentMetrics: {},
    announcementPreviousMetrics: {},
    monitoringRegions: [],
    noteworthyFires: [],
    myStates: [],
    selectedRegionIds: [],
    resSaveComments: false,
    dailyOperations: [],
    sevenDayTrends: [],
    unpubPHAs: -1,
    
    fireBoardClientsNoticeType: [],
    overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No Rows To Show</span>',
    resultOfSaveFireBoard: undefined,
    dialogParams: { show: false, moniterId:0, fireId:0}
}

/* Users */
export const fetchDutyOfficers = createAsyncThunk('actions/fetchDutyOfficers', async () => {
    let response = await getMembersByGroupName(Constants.SecurityGroups.DutyOfficer);
    return response || [];
});

export const fetchDCAs = createAsyncThunk('actions/fetchDCAs', async () => {
    let response = await getMembersByGroupName(Constants.SecurityGroups.DCA);
    return response || [];
});

export const fetchCurrentUserId = createAsyncThunk('actions/fetchCurrentUserId', async () => {
    let response = await getCurrentUserId();
    return response || '';
});

/* Announcement */
export const fetchAnnouncement = createAsyncThunk('actions/fetchAnnouncement', async () => {
    let response = await getAnnouncement();
    return response || {};
});

export const saveAnnouncement = createAsyncThunk('actions/saveAnnouncement', async (announcement: IAnnouncement) => {
    let response = await updateAnnouncement(announcement);
    return response || '';
});

export const fetchSevenDayTrends = createAsyncThunk('actions/fetchSevenDayTrends', async () => {
    let response = await getSevenDayTrends();
    return response.reverse() || {};
})

export const fetchUnpubPHAs = createAsyncThunk('actions/fetchUnpubPHAs', async () => {
    let response = await getUnpubPHAs();
    return response;
})

/* Map */
export const fetchMap = createAsyncThunk("actions/fetchMap", async () => {
    return getMap();
});

export const fetchMonitoringRegions = createAsyncThunk("actions/fetchMonitoringRegions", async () => {
    return getMonitoringRegions();
});

export const fetchMyStates = createAsyncThunk("actions/fetchMyStates", async () => {
    return getMyStates();
});

/* Daily Assignments */
export const fetchDailyOperations = createAsyncThunk('actions/fetchDailyOperations', async () => {
    let response = await getDailyOperations();
    return response || [];
});

export const saveDailyOperations = createAsyncThunk('actions/saveDailyOperations', async (operation: IDailyOperation) => {
    let response = await updateDailyOperations(operation);
    return response || false;
});

export const removeDailyOperations = createAsyncThunk('actions/removeDailyOperations', async (iaIGuid: string) => {
    let response = await deleteDailyOperations(iaIGuid);
    return response || false;
});

export const fetchFireBoard = createAsyncThunk('actions/fetchFireBoard', async () => {
    let response = await getFireBoard();
    return response || [];
});

export const saveFireBoardUsers = createAsyncThunk('actions/saveFireBoardUsers', async (param: IFireBoardUpdateParams) => {
    let response = await updateFireBoardUsers(param);
    return response || false;
});

export const fetchNoteworthyFires = createAsyncThunk('actions/fetchNoteworthyFires', async () => {
    let response = await getNoteworthyFires();
    return response || [];
});

export const setFireNoteworthyOff = createAsyncThunk('actions/setFireNoteworthyOff', async (param: any) => {
    let response = await sendFireNoteworthyOff(param);
    return response;
});

export const setFireReviewed = createAsyncThunk('actions/sendFireReviewed', async (param: IFireReviewedModel) => {
    let response = await sendFireReviewed(param);
    return response;
});

export const saveNoteworthyComments = createAsyncThunk('actions/saveNoteworthyComments', async (param: INoteworthyFireParams) => {
    let response = await updateNoteworthyComments(param);
    return response || false;
});

/** State slice with reducers, link reducer and action */
export const workflowManagementSlice = createSlice({
    name: "workflowManagement",
    initialState,
    reducers: {
        setMyStates: (state, action) => {
            state.myStates = action.payload;
        },
        setSelectedRegionIds: (state, action) => {
            state.selectedRegionIds = action.payload;
        },
        setAnnouncement: (state, action) => {
            state.announcement = action.payload;
        },
        setNoteworthyFires: (state, action) => {
            state.noteworthyFires = action.payload;
        },
        setResultOfSaveFireBoard: (state, action) => {
            state.resultOfSaveFireBoard = action.payload;
        },
        setDialogParams: (state, action) => {
            state.dialogParams = action.payload;

        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCurrentUserId.fulfilled, (state, action) => {
                state.currentUserId = action.payload;
            })
            .addCase(fetchDutyOfficers.fulfilled, (state, action) => {
                state.membersOfDutyOfficer = action.payload;
            })
            .addCase(fetchDCAs.fulfilled, (state, action) => {
                state.membersOfDCA = action.payload;
            })
            .addCase(fetchAnnouncement.fulfilled, (state, action) => {
                state.announcement = action.payload;
            })
            .addCase(saveAnnouncement.fulfilled, (state, action) => {
                state.announcement.dateUpdatedString = action.payload;
            })
            .addCase(fetchMap.fulfilled, (state, action) => {
                state.map = action.payload;
            })
            .addCase(fetchMonitoringRegions.fulfilled, (state, action) => {
                state.monitoringRegions = action.payload;
            })
            .addCase(fetchSevenDayTrends.fulfilled, (state, action) => {
                state.sevenDayTrends = action.payload;
            })
            .addCase(fetchUnpubPHAs.fulfilled, (state, action) => {
                state.unpubPHAs = action.payload;
            })
            .addCase(fetchMyStates.fulfilled, (state, action) => {
                state.myStates = action.payload;
            })
            .addCase(fetchNoteworthyFires.fulfilled, (state, action) => {
                state.noteworthyFires = action.payload;
            })
            .addCase(setFireNoteworthyOff.fulfilled, (state, action) => {
                if (action.payload!=null)
                    state.noteworthyFires = action.payload;
            })         
            .addCase(setFireReviewed.fulfilled, (state, action) => {
                if (!action.payload)
                    return;

                if (!action.payload.fireId)
                    return;

                let index = state.noteworthyFires.findIndex(f => f.fireId == action.payload.fireId);
                if (index > -1) {
                    state.noteworthyFires[index].reviewed = action.payload.reviewed;
                }

            })
            .addCase(saveNoteworthyComments.fulfilled, (state, action) => {
                state.resSaveComments = action.payload;
            })
            .addCase(fetchDailyOperations.fulfilled, (state, action) => {
                state.dailyOperations = action.payload;
            })
            .addCase(saveDailyOperations.fulfilled, (state, action) => {
                console.log(action.payload);
            })
            .addCase(removeDailyOperations.fulfilled, (state, action) => {
                console.log(action.payload);
            })
            .addCase(fetchFireBoard.fulfilled, (state, action) => {
                state.fireBoardClientsNoticeType = action.payload;
            })
            .addCase(saveFireBoardUsers.fulfilled, (state, action) => {
                state.resultOfSaveFireBoard = action.payload;
            })
    }
});

/** Action creators are generated for each case reducer function */
export const {
    setMyStates,
    setSelectedRegionIds,
    setAnnouncement,
    setNoteworthyFires,
    setResultOfSaveFireBoard,
    setDialogParams
} = workflowManagementSlice.actions;

export default workflowManagementSlice.reducer;

