import { getJson, postJson } from "utils";
import { IClient, IUser } from "./models/IAdmin";

export async function getExternalUsers(): Promise<IUser[]> {
    return getJson('/api/Admin/ExternalUsers/GetAll');
}

export async function postInviteUser(data: any): Promise<any> {
    return postJson('/api/admin/externalusers/invite', data);
}

export async function deleteUser(userId: string): Promise<any> {
    return postJson(`/api/admin/externalusers/delete/${userId}`, {});
}

export async function updateUser(user: any): Promise<any> {
    return postJson('/api/Admin/ExternalUsers/Update', user);
}

export async function getClients(): Promise<IClient[]> {
    return postJson('/api/admin/clients/query', {}).then(res => res?.data || []);
}

export async function postClient(data: any): Promise<IClient> {
    return postJson('/api/admin/clients/add', data);
}

export async function deleteClient(clientId: number): Promise<any> {
    return postJson(`/api/admin/clients/remove/${clientId}`, {});
}

export async function updateClient(client: any): Promise<any> {
    return postJson('/api/admin/clients/update', client);
}