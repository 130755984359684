import {
    IReportQuery,
    ICardData,
    IChangeInProperties,
    IFireChartData,
    IDispatchFireData,
    IStateChartData,
    IActionType,
} from "./models";
import { ICurrentProperties } from "./models/programUse/ICurrentProperties";
import { getJson, postJson, deleteRequest } from "../utils";

function getNameWithState(s: any): string {
    if (s) {
        return s.state && s.state.length ? `${s.name}, ${s.state}` : s.name;
    }
    return "";
}

function getFireNameWithState(s: any): string {
    if (s) {
        return s.state && s.state.length ? `${s.fireName}, ${s.state}` : s.fireName;
    }
    return "";
}

function getNextDate(input: string): Date {
    let date = new Date(input);
    date.setDate(date.getDate() + 1);
    return date;
}

// get property tiles
export async function getPropertiesCurrent(
    query: IReportQuery
): Promise<Array<ICurrentProperties>> {
    var result = await postJson(
        "/api/programuse/properties/propertiescurrent", 
        query
    );
    if (result && result.length) {
        return result.map(
            (s: any) =>
            ({
                group: s.group,
                client: s.client,
                state: s.state,
                total: s.total,
                enrolled: s.enrolled,
                notEnrolled: s.notEnrolled,
                other: s.other,
                unmatched: s.unmatched
            } as ICurrentProperties)
        );
    }
    return [];
}

// get property changes
export async function getChangesInProperties(
    query: IReportQuery
): Promise<Array<IChangeInProperties>> {
    var result = await postJson(
        "/api/programuse/properties/changeovertime",
        query
    );
    if (result && result.length) {
        return result.map(
            (s: any) =>
            ({
                date: s.date,
                client: s.name,
                new: s.new,
                canceled: s.canceled,
                total: s.active,
                geocodeAttempts: s.geocodeAttempts,
                geocodes: s.geocodes
            } as IChangeInProperties)
        );
    }
    return [];
}

// get program fires count
export async function getProgramFiresCount(
    query: IReportQuery
): Promise<Array<ICardData>> {
    var result = await postJson("/api/programuse/programfires/counts", query);
    if (result) {
        return [
            { type: "Dispatched Fires", count: result.dispatchedFires },
            { type: "Total Program Fires", count: result.totalProgramFires },
        ];
    }
    return [];
}

// get program fires count
export async function getDispatchedFires(
    query: IReportQuery
): Promise<Array<IDispatchFireData>> {
    var result = await postJson(
        "/api/programuse/programfires/dispatchfirehistories",
        query
    );
    if (result && result.length) {
        const data: IDispatchFireData[] = [];
        result.forEach((s: any) => {
            if (
                s.firstResponding &&
                s.firstResponding.length &&
                s.lastResponding &&
                s.lastResponding.length
            ) {
                data.push({
                    fire_id: s.id,
                    fire_name: getNameWithState(s),
                    type: "Program Responding",
                    from: new Date(s.firstResponding).valueOf(),
                    to: getNextDate(s.lastResponding).valueOf(),
                });
            }
            if (
                s.firstOnIncident &&
                s.firstOnIncident.length &&
                s.lastOnIncident &&
                s.lastOnIncident.length
            ) {
                data.push({
                    fire_id: s.id,
                    fire_name: getNameWithState(s),
                    type: "Program Resources On Incident",
                    from: new Date(s.firstOnIncident).valueOf(),
                    to: getNextDate(s.lastOnIncident).valueOf(),
                });
            }
            if (
                s.firstDemobilization &&
                s.firstDemobilization.length &&
                s.lastDemobilization &&
                s.lastDemobilization.length
            ) {
                data.push({
                    fire_id: s.id,
                    fire_name: getNameWithState(s),
                    type: "Program Resources On Demobilized",
                    from: new Date(s.firstDemobilization).valueOf(),
                    to: getNextDate(s.lastDemobilization).valueOf(),
                });
            }
        });
        return data;
    }
    return [];
}

// get program fires count
export async function getThreatenedProperties(
    query: IReportQuery
): Promise<Array<IFireChartData>> {
    var result = await postJson(
        "/api/programuse/programfires/potentiallythreatenedproperties",
        query
    );
    if (result && result.length) {
        return result.map(
            (s: any) =>
            ({
                fire_id: s.fireId,
                fire_name:
                    s.state && s.state.length
                        ? `${s.fireName}, ${s.state}`
                        : s.fireName,
                count: s.properties,
            } as IFireChartData)
        );
    }
    return [];
}

// get all reports
export async function getReports(): Promise<Array<IReportQuery>> {
    var result = await getJson("/api/programuse/reports");
    if (result && result.data && result.data.length) {
        return result.data;
    }
    return [];
}

// get all reports
export async function getReport(id: string | number): Promise<IReportQuery> {
    return getJson(`/api/programuse/reports/details/${id}`);
}

// save report
export async function postReport(query: IReportQuery): Promise<number> {
    return postJson("/api/programuse/reports/post", query);
}

// get program fires count
export async function deleteReport(id: number): Promise<boolean> {
    return deleteRequest(`/api/programuse/reports/delete?id=${id}`);
}

// get map
export async function getMap(): Promise<any> {
    return fetch("/states-10m.json").then((r) => r.json());
}

// get monitored fires
export async function getMonitoredFire(
    query: IReportQuery
): Promise<Array<IStateChartData>> {
    var result = await postJson(
        "/api/programuse/monitoredfires/statistics",
        query
    );
    const data: IStateChartData[] = [];
    if (result && result.length) {
        result.forEach((s: any) => {
            data.push(
                {
                    state: s.state,
                    type: "Fires Monitored",
                    count: s.fires || 0,
                },
                {
                    state: s.state,
                    type: "Fires With Properties in Proximity",
                    count: s.firesWithPropertiesInProximity || 0,
                },
                {
                    state: s.state,
                    type: "Properties in Proximity to Monitored Fires",
                    count: s.propertiesInProximity || 0,
                }
            );
        });
    }
    return data;
}


// get actions by fires
export async function getActionTypes(
    query: IReportQuery
): Promise<Array<ICardData>> {
    var result = await postJson(
        "/api/programuse/visits/actionstakenbytype",
        query
    );
    let ids = query.actionIds || []
    if (result && result.length) {
        return result.filter((s: any) => ids.includes(s.actionTypeId)).map((s: any) => ({ type: s.actionTypeName, count: s.count }))
    }
    return [];
}


// get action types
export async function getAllActionTypes(): Promise<Array<IActionType>> {
    var result = await getJson(
        "/api/programuse/reports/getactions",
    );
    return result?.data || [];
}

// get visits by fires
export async function getVisitsByFire(
    query: IReportQuery
): Promise<Array<IFireChartData>> {
    var result = await postJson(
        "/api/programuse/visits/byfire",
        query
    );
    if (result && result.length) {
        const res: IFireChartData[] = [];
        result.forEach((s: any) => {
            if (s.properties) {
                res.push({
                    fire_id: s.fireId,
                    fire_name: getFireNameWithState(s),
                    type: 'Total Properties Visited',
                    count: s.properties,
                })
            }
            if (s.visits) {
                res.push({
                    fire_id: s.fireId,
                    fire_name: getFireNameWithState(s),
                    type: 'Total Visits',
                    count: s.visits,
                })
            }
        });
        return res;
    }
    return [];
}

// get home status by fires
export async function getHomeStatusByFires(
    query: IReportQuery
): Promise<Array<IFireChartData>> {
    var result = await postJson(
        "/api/programuse/visits/lasthomestatuebyfire",
        query
    );
    if (result && result.length) {
        return result.map((s: any) => ({
            fire_id: s.fireId,
            fire_name: s.state && s.state.length ? `${s.fireName}, ${s.state}` : s.fireName,
            type: s.lastStatus,
            count: s.count
        }));
    }
    return [];
}