import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import demobilizedFire from "assets/img/legendSymbols/demobilized-fire.png";
import nonDispatchedFire from "assets/img/legendSymbols/non-dispatchedfire.png";
import { ILayerItem, ILayerSelectorSettings, IResClient } from "api/models";
import { IProgramFireData, getProgramFireCount, getResClients } from "api/historicFiresAPI";
import { Constants } from "utils/constants";
import { generateTimelineItems, formatFireSize } from "./util";

interface IHistoricFiresState {
    legendItems: { text: string, imgSrc?: string, color?: string }[];
    fireTypes: ILayerItem[];
    timeFrame: ILayerItem[];
    clients?: IResClient[];
    layerSettings: ILayerSelectorSettings;
    programFires?: IProgramFireData[];
    noteworthyFires?: IProgramFireData[];
    monitoredFires?: IProgramFireData[];
    monitoredAndNoteworthyFires?: IProgramFireData[];
    selectedClientId?: number;  //internal user, one client is selected
    selectedFire?: any;
}

const initialState: IHistoricFiresState = {
    legendItems: [
        { text: 'Non-Dispatched Fire', imgSrc: nonDispatchedFire },
        { text: 'Demobilized Fire', imgSrc: demobilizedFire },
    ],
    fireTypes: [
        { text: "Non-Dispatched Fire", value: Constants.FireType_NonDispatchedFire },
        { text: "Demobilized Fire", value: Constants.FireType_DemobilizedFire }
    ],
    timeFrame: [],
    layerSettings: {
        fireTypes: [Constants.FireType_DemobilizedFire],
        timeFrame: "",
        layers: []
    },
    programFires: []
}

export const setSelectedClientId = createAsyncThunk(
    "actions/setSelectedClientId",
    async (clientId: number) => {
        return getProgramFireCount(clientId).then(({ data }) => {
            return generateTimelineItems(data);
        });
    }
);

export const fetchClients = createAsyncThunk(
    "global/fetchClients",
    async () => {
        return getResClients();
    }
);

const historicFiresActions = createSlice({
    name: "historicFiresActions",
    initialState,
    reducers: {
        setLayerSettings: (state, action) => {
            state.layerSettings = action.payload;
        },
        setProgramFires: (state, action) => {
            state.programFires = action.payload;
        },
        setNoteworthyFires: (state, action) => {
            state.noteworthyFires = action.payload;
        },
        setMonitoredFires: (state, action) => {
            state.monitoredFires = action.payload;
        },
        setMonitoredAndNoteworthyFires: (state, action) => {
            state.monitoredAndNoteworthyFires = action.payload;
        },
        setSelectedFire: (state, action) => {
            let selectedFire = action.payload;
            const contained = selectedFire.containment && selectedFire.containment !== "-1" ? `${selectedFire.containment.replace("%", "")}% Contained<br/>` : "";

            const comments =
                selectedFire.comments && selectedFire.comments.length > 150
                    ? `${selectedFire.comments.substring(0, 150)}...`
                    : selectedFire.comments;
            const moreInfo = `${selectedFire.city}, ${selectedFire.state}<br/>Acreage: ${formatFireSize(selectedFire.size)}</br>${contained}</br>${comments}`
            state.selectedFire = {
                ...action.payload,
                moreInfo
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setSelectedClientId.pending, (state, action) => {
            state.layerSettings = {
                fireTypes: state.layerSettings.fireTypes,
                timeFrame: "",
                layers: []
            };
            state.programFires = [];
            state.timeFrame = [];
            state.selectedClientId = action.meta.arg;
        });
        builder.addCase(setSelectedClientId.fulfilled, (state, action) => {
            state.timeFrame = action.payload;
            if (action.payload.length) {
                state.layerSettings = {
                    fireTypes: state.layerSettings.fireTypes,
                    timeFrame: action.payload[0].value,
                    layers: []
                }
            }
        });
        //fetchClients
        builder.addCase(fetchClients.fulfilled, (state, action) => {
            state.clients = action.payload;
        });
    }
});

export const {
    setLayerSettings,
    setProgramFires,
    setNoteworthyFires,
    setMonitoredFires,
    setMonitoredAndNoteworthyFires,
    setSelectedFire,
} = historicFiresActions.actions;
export default historicFiresActions.reducer;