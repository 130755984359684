import { postJson } from "../utils/index";
import { IResClient } from "./models";

/*program fires datatbale --begin*/
export interface ISortField {
    Name: string,
    IsDesc: boolean
}
export interface IProgramFireDataFilter {
    pagination?: {
        pageSize: number,
        pageNumber: number
    },
    Filter: {
        WdsStatus: number, ClientId?: number, Year: number, SearchKey?: string
    },
    Sorting?: ISortField[]
}
export interface IProgramFireData {
    id: number,
    name: string,
    city: string,
    state: string,
    size: string,
    containment: string,
    status: string,
    comments: string,
    updated: string,
    coordLat: string,
    coordLong: string,
    wdsStatus: number,
    fireId: number,
    clientId: number
}
export interface IProgramFireRes {
    meta: {
        totalCount: number
    }
    data: IProgramFireData[]
}

export async function getProgramFireData(params: IProgramFireDataFilter): Promise<IProgramFireRes> {
    const { pagination, Filter, Sorting } = params;
    if (pagination && (isNaN(pagination.pageNumber) || pagination.pageNumber <= 0)) {
        pagination.pageNumber = 1
    }
    if (pagination && (isNaN(pagination.pageSize) || pagination.pageSize <= 0)) {
        pagination.pageSize = 10
    }
    return postJson(
        '/api/historicFires/fires/programfire/' + (Filter.ClientId ?? 0),
        {
            pagination: pagination,
            Filter: Filter,
            Sorting: !Sorting || Sorting[0].Name.length === 0 ? null : Sorting
        }
    );
}

export async function getProgramFireCount(clientId: number): Promise<IProgramFireRes> {
    return postJson(
        '/api/historicFires/fires/fireCount',
        {
            Filter: { ClientId: clientId },
        }
    );
}

export async function getResClients(): Promise<IResClient[]> {
    return postJson('/api/historicFires/fires/clients', {}).then(res => {
        const result = res?.data || [];
        result.sort((a: IResClient, b: IResClient) => a.id - b.id);
        return result;
    });
}