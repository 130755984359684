import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IWFIGSFire, IGeogStates } from "../../api/models";
import { getFiresFromWFIGS, getGeogStates } from "../../api/monitoringHeadquartersAPI";
import dispatchedFire from "assets/img/legendSymbols/dispatched-fire.png";
import nonDispatchedFire from "assets/img/legendSymbols/non-dispatchedfire.png";

//define the state for Monitoring Headquarters
export interface MonitoringHeadquartersState {
    pageTitle: string;
    wfigsFires: IWFIGSFire[];
    filteredWfigsFires: IWFIGSFire[];
    selectedStates: string[];
    legendItems: { text: string, imgSrc?: string, color?: string }[];
    geogStates: IGeogStates[];
}

const initialState: MonitoringHeadquartersState = {
    pageTitle: '',
    wfigsFires: [],
    filteredWfigsFires: [],
    selectedStates: [],
    legendItems: [
        { text: 'Fire Data', imgSrc: dispatchedFire },
        { text: 'Updated Fire', imgSrc: nonDispatchedFire}
    ],
    geogStates:[],
}

export const fetchFiresFromWFIGS = createAsyncThunk('actions/fetchFiresFromWFIGS', async () => {
    let response = await getFiresFromWFIGS();
    return response || [];
});

export const fetchGeogStates = createAsyncThunk('actions/fetchGeogStates', async () => {
    let response = await getGeogStates();

    return response || [];
});

/** State slice with reducers, link reducer and action */
export const monitoringHeadquartersSlice = createSlice({
    name: "monitoringHeadquarters",
    initialState,
    reducers: {

        setWfigsFires: (state, action) => {
            state.wfigsFires = action.payload;            
        },

        setFilteredWfigsFires: (state, action) => {
            state.filteredWfigsFires = action.payload;
        },

        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },

        setSelectedStates: (state, action) => {
            state.selectedStates = action.payload;
        },  
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFiresFromWFIGS.fulfilled, (state, action) => {
                const data = action.payload;
                const filteredData = data.filter((e: any) => e.acreage >= 0.1);
                state.wfigsFires = filteredData;
            })
            .addCase(fetchGeogStates.fulfilled, (state, action) => {
                state.geogStates = action.payload;
            })
    }
});

/** Action creators are generated for each case reducer function */
export const {
    setSelectedStates,
    setWfigsFires,
    setFilteredWfigsFires,
    setPageTitle,
} = monitoringHeadquartersSlice.actions;

export default monitoringHeadquartersSlice.reducer;

