import $ from "jquery";
import { getId } from "@fluentui/react";

import { ERROR_PREFIX } from "utils/consts";

export type KeysOfStringValue<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];
export const isNumber = (obj: any): obj is number => typeof obj === "number" && obj === obj;
export const isFunction = (obj: any): obj is Function => typeof obj === "function";
export const isString = (obj: any): obj is string => typeof obj === "string";
export const isUndefined = (obj: any): obj is undefined => typeof obj === "undefined";

export const makeError = (err: { error: any; correlationId?: string }) => {
  const { error: message, correlationId } = err;
  if (!message) {
    return null;
  }

  let msg: string = "";
  if (isString(message)) {
    msg = message;
  } else if (message instanceof Error) {
    msg = message.message;
  } else {
    //other types
  }

  return { id: getId(ERROR_PREFIX), correlationId: correlationId || "", message: msg };
};

export const capitalize = (str: string) => {
  if (!isString(str)) {
    return str;
  }
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const getItem = <T>(arr: T[], callbackFn: (item: T) => boolean) => {
  let result = null,
    resultIndex = -1;
  for (let index = 0; index < arr.length; index++) {
    if (callbackFn(arr[index])) {
      result = arr[index];
      resultIndex = index;
      break;
    }
  }
  return { result, resultIndex };
};

export const groupBy = <T, Key extends keyof T>(items: T[], key: Key) => {
  return items.reduce((acc: any, item: T) => {
    acc[item[key]] = acc[item[key]] || [];
    acc[item[key]].push(item);
    return acc;
  }, {});
};

export const sortBy = <T>(items: T[], fn: (s1: T, s2: T) => number) => {
  return [...items].sort(fn);
};

export const getCount = <T>(items: T[], fn: (item: T) => boolean) => {
  let count = 0;
  items.forEach((itm) => {
    if (fn(itm)) {
      count++;
    }
  });
  return count;
};

export const arrayAll = <T>(items: T[], fn: (item: T) => boolean) => {
  for (const item of items) {
    if (!fn(item)) {
      return false;
    }
  }
  return true;
};

export const findDuplicates = <T>(items: T[], indexFn: (arr: T[], item: T) => number) => {
  const results: T[] = [];
  const duplicates = items.filter((itm, index) => indexFn(items, itm) !== index);
  for (const dp of duplicates) {
    if (!~indexFn(results, dp)) {
      results.push(dp);
    }
  }
  return results;
};

/**
 * join an object arrary on a single property which value type is of string.
 */
export function joinKeyValues<T>(items: T[], key: (item: T) => string, separator: string, sortAsc?: boolean, removeEmpty?: boolean): string;
export function joinKeyValues<T>(items: T[], key: keyof T, separator: string, sortAsc?: boolean, removeEmpty?: boolean): string;
export function joinKeyValues<T>(items: T[], key: ((item: T) => string) | keyof T, separator: string, sortAsc?: boolean, removeEmpty: boolean = true) {
  let values = items.map((m) => {
    if (isFunction(key)) {
      return key(m);
    } else {
      return m[key];
    }
  });

  if (removeEmpty) {
    values = values.filter((v) => v);
  }

  if (sortAsc) {
    values.sort((a, b) => (a as string).localeCompare(b as string));
  }

  return values.join(separator);
}

export const intersection = <T>(fn: (item1: T, item2: T) => boolean, ...args: T[][]) => {
  return args.reduce((arr1, arr2) => {
    let res = [];
    for (const i1 of arr1) {
      if (~arr2.findIndex((i2) => fn(i1, i2))) {
        res.push(i1);
      }
    }
    return res;
  });
};

export const getNameFromEmail = (email?: string) => email?.split("@")[0];

export const getPaginationInfo = (startRow: number, endRow: number) => {
  if (startRow >= endRow) {
    console.error("cannot determine pagination info");
    return [0, 0];
  }

  const pageSize = endRow - startRow;
  const pageNumber = startRow / pageSize + 1;

  return [pageSize, pageNumber];
};

/**convert date string to local date time string */
export const getLocalDateTimeString = (dateString?: string | null) => {
  if (dateString && Date.parse(dateString)) {
    return new Date(dateString).toLocaleString();
  } else return dateString;
};

export const getJson = (url: string) => {
  return $.ajax({ url });
};

export const postJson = (url: string, data: any) => {
  return $.ajax({
    url,
    type: "POST",
    dataType: "json",
    contentType: "application/json",
    data: JSON.stringify(data),
  });
};

export const deleteRequest = (url: string) => {
  return $.ajax({
    url,
    type: "DELETE",
  });
};

export const postJsonWithNoTrack = (url: string, data: any) => {
  return fetch(url, {
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  });
};

export const postWithFile = (url: string, data: any) => {
  return $.ajax({
    type: "POST",
    url: url,
    data: data,
    contentType: false,
    processData: false,
  });
};

export const downloadFile = (file: any) => {
  if (!file.path?.length) {
    return;
  }
  const fileName = file.name?.length ? file.name : file.path.substr(file.path.lastIndexOf("/") + 1);
  const elink = document.createElement("a");
  elink.download = fileName;
  elink.style.display = "none";
  elink.href = file.path;
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
};

export const toThousands = (num: number | undefined): string => {
  var numText = (num || 0).toString(),
    result = "";
  while (numText.length > 3) {
    result = "," + numText.slice(-3) + result;
    numText = numText.slice(0, numText.length - 3);
  }
  if (numText) {
    result = numText + result;
  }
  return result;
};

export const deepCopy = (input: any) => JSON.parse(JSON.stringify(input));

/**
 * export file
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postDownload(url: string, fileName: string, options: any = {}) {
  return new Promise((resolve, reject) => {
    let testRequest = new Request(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8;",
      },
      body: JSON.stringify(options),
    });
    fetch(testRequest)
      .then((res) => res.blob())
      .then(
        (data) => {
          resolve(data);
          let blob = new Blob([data]);
          let nav: any = window.navigator;
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveBlob(blob, fileName);
          } else {
            let blobUrl = window.URL.createObjectURL(blob);
            downloadFile({ name: fileName, path: blobUrl });
            window.URL.revokeObjectURL(blobUrl);
          }
        },
        (err) => {
          reject(err);
        }
      );
  });
}

export function defaultNull<T>(input: T | undefined, defaultValue: T): T {
  return input || defaultValue;
}

export const downloadKMZ = (url: string, param: any) => {
  $.ajax({
    url: url,
    type: "post",
    data: JSON.stringify({ Filter: param }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    xhrFields: {
      responseType: "blob",
    },

    success: function (data) {
      if (data.size == 0) {
        alert("This fire has no or enough perimeter information, can't generate KMZ/KML file.");
        return;
      }
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", (param.fireName || param.FireName) + ".kmz"); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  });
};

export const getFileToDownload = (url: string, param: any) => {
  return $.ajax({
    url: url,
    type: "post",
    data: JSON.stringify(param),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    xhrFields: {
      responseType: "blob",
    },
  });
};

export const linkToDownload = (data: any, fileName: string) => {
  if (!data) return;
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const seeds = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_";
export function generateRandomStr(length: number): string {
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(seeds[Math.floor(Math.random() * seeds.length)]);
  }
  return result.join("");
}

export function log(section: string, text: string, ...args: any[]) {
  let a = "background:#f2b643;color:black;border-radius:4px 0 0 4px;padding:2px 5px";
  let b = "background:black;color:white;border-radius:0 4px 4px 0;padding: 2px 5px;";
  console.log(`%cWDS%c${section}%c ${text}`, a, b, "", ...args);
}

export const handlePropertyStatus = (status: string | undefined) => {
    let statusNew = status?.toLowerCase();

    if(statusNew?.includes('undamaged')) {
        return status;
    } else if(statusNew?.includes('damaged')) {
        return 'Damaged';
    } else if(statusNew?.includes('lost')) {
        return 'Lost';
    } else {
        return status;
    }
}

export const handleWdsStatus = (fireDetails: any) => {
  if (fireDetails.wdsStatus) {
    switch (fireDetails.wdsStatus) {
      case 1:
        return "Dispatched";
      case 2:
        return "Non-Dispatched";
      case 3:
        return "Demobilized";
    }
  } else if (fireDetails.noteworthy) {
    return "Noteworthy";
  } else {
    return "Monitored";
  }
};

export const fontColorByStatus = (status: number | undefined) => {
  switch (status) {
    case 1:
      return "red-warn";
    case 3:
      return "demob-color";
    case 2:
      return "non-dispatch-color";
    default:
      return "";
  }
};

export const wdsLobToString = (lob: number) => {
  switch (lob) {
    case 1:
      return "Residential";
    case 2:
      return "Commercial";
    case 3:
      return "Agricultural";
    case 4:
      return "MGU";
    default:
      return "";
  }
};

export const createExcerpt = (str: string, length: number) => {
  if(str.length > length) {
    str = str.substring(0,length) + '...';
  }
  return str;
}
