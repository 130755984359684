import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "app/hooks";
import { SIGNIN_PATH } from "utils/consts";
import { AccessDenied } from "./AccessDenied";

export type IAuthTemplateProps = PropsWithChildren<{}> & {
    internalOnly?: boolean;
    roles?: string[];
    sites?: string[];
};

export const AuthTemplate = ({ children, internalOnly, roles, sites }: IAuthTemplateProps) => {
    const ctx = useAuthContext();
    const { pathname } = useLocation();
    if (ctx.isAuthenticated) {
        if (
            (internalOnly === (ctx.isInternalUser ? internalOnly : false))
            && (!roles || roles?.some(r => ctx.role?.includes(r)))
            && (!sites || sites.includes(ctx.site || ''))
        ) {
            return <>
                {children}
            </>;
        }
        else {
            return <AccessDenied />;
        }
    }

    window.location.href = SIGNIN_PATH + (pathname ? `?redirectUri=${pathname}` : '');
    return null;
}