import { createSlice } from "@reduxjs/toolkit";
// This slice is switches for tabs, when enter the page, 
// it's better NOT make all tab data load together, 
// because only one tab you can saw each time.
// Switch = 1 -> opened, data load and showed.
// Default 0-> It's NOT the time data starts loading.
interface ITabControlState {
    propertyListSwitch: number;
    preFireInforSwitch: number;
    seeAllVisitsSwitch: number;
}

const initialState: ITabControlState = {
    propertyListSwitch: 1,
    preFireInforSwitch: 0,
    seeAllVisitsSwitch: 0
}

export const tabControlSlice = createSlice({
    name: "tabControl",
    initialState,
    reducers: {
        setPropertyListSwitch: (state, action) => {
            state.propertyListSwitch = action.payload as number;
        },
        setPreFireInforSwitch: (state, action) => {
            state.preFireInforSwitch = action.payload as number;
        },
        setSeeAllVisitsSwitch: (state, action) => {
            state.seeAllVisitsSwitch = action.payload as number;
        },
    },
    
});

export const {
    setPropertyListSwitch,
    setPreFireInforSwitch,
    setSeeAllVisitsSwitch
} = tabControlSlice.actions;

export default tabControlSlice.reducer;