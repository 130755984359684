import React from 'react';
import { IContextualMenuItem, DefaultButton, mergeStyles } from "@fluentui/react";


export interface INavigationGroup {
    text: string;
    key: string;
    items?: IContextualMenuItem[];
}

export interface INavigationProps {
    groups: INavigationGroup[];
}

export const Navigation = ({ groups }: INavigationProps) => {
    const path = window.location.pathname.toLowerCase();
    const tabs = React.useMemo(() => {
        return groups.map((g, index) => {
            const menuProps = { items: g.items || [] };
            const itemIndex = g.items?.findIndex(i => i.href && i.href.toLowerCase().indexOf(path) > -1);
            const shouldHightLight = path !== '/' && itemIndex !== undefined && itemIndex > -1;
            const shouldAlignRight = index === groups.length - 1 && g.key.toLowerCase() === "admin";
            const btnClassName = mergeStyles('nav-item', shouldHightLight && 'is-active', shouldAlignRight && 'align-right');
            return <DefaultButton className={btnClassName} key={g.key} text={g.text} persistMenu menuProps={menuProps} />;
        });
    }, [groups, path]);

    return <div className="d-flex flex-row align-items-center nav-wrap">
        {tabs}
    </div>;
}