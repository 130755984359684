import { postJson } from "../utils/index";
import { IPropertyInFire } from "./models";

export interface IPropertyInFireParam {
  firePerimeter: any;
  fireId: number;
}

export async function GetMguPropsNearFire(params: IPropertyInFireParam): Promise<IPropertyInFire> {
  return postJson("/api/smokecheck/fires/GetMguProps", {Filter: params}).then((res) => res);
}
