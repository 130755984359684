import { PropsWithChildren } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { useAuthContext } from "app/hooks";
import { AccessDenied } from "./AccessDenied";
import { SIGNIN_PATH } from "utils/consts";
import { AccountType } from "./AuthContext";
import { Constants } from "utils/constants";

export type IAuthTemplateProps = PropsWithChildren<{}> & {
    accountTypes?: AccountType[];
    role?: string;
    sites?: string[];
};

// const hasRoles = (roles: string[], ...rolesToSearch: string[]): boolean => {
//     for (const role of rolesToSearch) {
//         if (roles.indexOf(role) >= 0) {
//             return true;
//         }
//     }
//     return false;
// }

export const AuthRedirect = () => {
    const ctx = useAuthContext();
    const { pathname } = useLocation();
    // if user is authenticated
    if (ctx.isAuthenticated) {
        // if there are roles assigned to user (roles are stored in an array)
        if (ctx.role) {
            // if the user has the sitmap role redirect to sitmap
            if (ctx.isInternalUser) {
                return (<Redirect to="/sitmap" />);
             // if the user isnt a sitmap user but is a CLPEditor or a Clients user return the plp page
            } else if (ctx.isExternalUser) {
                return (<Redirect to="/plp" />);
            } else {
                // if there are no roles assigned to user return access denied page
                return (<AccessDenied />);
            }
        } else {
            // if no other cases are tuue, default to access denied
            return (<AccessDenied />);
        }
    }

    // if not authenticated, reroute to signin
    window.location.href = SIGNIN_PATH + (pathname ? `?redirectUri=${pathname}` : '');
    return null;
}