import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    IFireDetailSmokeCheck,
    IEnginItem, IWorkZoneSet
} from "api/models";
import { getProperties, updateWorkZones } from "api/workZonesAPI";
import * as atlas from "azure-maps-control";

import { deepCopy } from "../../utils";

interface IWorkzonesState {
    workZoneSets: IWorkZoneSet[];
    workZones: atlas.data.Feature<any, any>[],
    selectedWorkZoneSetId?: number;
    selectedWorkZoneId?: string | number;
    editMode: boolean;
    savedState: boolean | undefined;
    wdsFireDetail?: IFireDetailSmokeCheck;
    propertiesInFire?: atlas.data.FeatureCollection;
    wdsFirePerimeter?: atlas.data.FeatureCollection;
    showWorkZoneLabels: boolean;
    enginesByFire?: any[];
    showConfirmDialog: boolean;
    dialogParams?: any;
    selectedProperties?: number;
    //If there is an error in map editing, the 'Done' button is invalid
    hasMapError?: boolean;
    canEdit: boolean;
    editing: boolean;
    changed: boolean;
    showCloseConfirm: boolean;
    // legendItems: { text: string; imgSrc?: string; color?: string }[];
}

const initialState: IWorkzonesState = {
    workZoneSets: [],
    workZones: [],
    selectedWorkZoneSetId: 0,
    selectedWorkZoneId: 0,
    editMode: false,
    savedState: undefined,
    showConfirmDialog: false,
    dialogParams: { actionNum: 1 },
    wdsFireDetail: {},
    showWorkZoneLabels: true,
    propertiesInFire: undefined,
    wdsFirePerimeter: undefined,
    enginesByFire: [],
    hasMapError: false,
    canEdit: false,
    editing: false,
    changed: true,
    showCloseConfirm: false,

    // legendItems: [
    //     { text: "Fire Perimeter", imgSrc: FirePerimeter },
    //     { text: "Threat Perimeter", imgSrc: ThreatPerimeter },
    //     { text: ".5 Mile Buffer", imgSrc: Dot5MileBuffer },
    //     { text: "1 Mile Buffer", imgSrc: OneMileBuffer },
    //     { text: "3 Mile Buffer", imgSrc: ThreeMileBuffer },
    //     { text: "Alert Distance", imgSrc: AlertDistance },
    //     { text: "Previous Fires", imgSrc: PreviousFires },
    //     { text: "Res. Enrolled", imgSrc: ResEnrolled },
    //     { text: "Res. Not Enrolled", imgSrc: ResNotEnrolled },
    //     { text: "Res. Other", imgSrc: ResOther },
    //     { text: "Comm. Enrolled", imgSrc: CommEnrolled },
    //     { text: "Comm. Not Enrolled", imgSrc: CommNotEnrolled },
    //     { text: "Comm. Other", imgSrc: CommOther },
    //     { text: "Agri. Enrolled", imgSrc: AgriEnrolled },
    //     { text: "Agri. Not Enrolled", imgSrc: AgriNotEnrolled },
    //     { text: "Agri. Other", imgSrc: AgriOther },
    //     { text: "MGU", imgSrc: MguEnrolled },
    //     { text: "Visited Property", imgSrc: VisitedFlagIcon },
    //     { text: "Rehab Required", imgSrc: RehabRequiredFlagIcon },
    //     { text: "Engine", imgSrc: ActiveFireEngineIcon },
    // ],
};

/** API start */
export const fetchPropertiesInFire = createAsyncThunk(
    "actions/fetchPropertiesInFire",
    async (fireId: number) => {
        let response = await getProperties(fireId);
        return response || {};
    }
);

export const submitWorkZones = createAsyncThunk(
    "actions/submitWorkZones",
    async (payload:any) => {
        let response = await updateWorkZones(payload);
        return response || {};
    }
);

/** API end */

export const getNewLabel = (workZones: []) => {

    let i = 1;
    let label = 'wz' + i;
    while (!validateLabel(workZones, label)) {
        i++;
        label = 'wz' + i;
    }

    return label;
}

export const validateLabel = (workZones: atlas.data.Feature<any, any>[], label: string) => {
    if (!label || label.length < 1)
        return false;

    let index = workZones.findIndex((w: any) => w.properties.label === label);
    if (index > -1) {
        return false;
    }

    return true;
}


const updateSelectedWorkZone = (state: any) => {
    const selectedWorkZone = state.workZones.find(
        (s: any) => s.id === state.selectedWorkZoneId
    );
    if (selectedWorkZone) {
        state.selectedProperties =
            selectedWorkZone.properties?.triggeredIds?.length;
    } else {
        state.selectedWorkZoneId = undefined;
    }
}

const renewWorkZones = (state: any) => {
    let index = state.workZoneSets.findIndex((s: any) => s.id === state.selectedWorkZoneSetId);
    
    if (index > -1) {
        state.workZones = deepCopy(state.workZoneSets[index].workZones);
        state.selectedWorkZoneSetId = state.workZoneSets[index].id;
    } else {
        state.workZones = [];
    }
    updateSelectedWorkZone(state);
}

export const moveEngine = (workZones: any, payload: any) => {
    if (!workZones)
        return;

    let index: number = -1;
    let enginItem: null | IEnginItem = null;

    if (payload.oldWzId != undefined) {
        let oldWz = workZones.find((wz: any) => wz.id === payload.oldWzId);

        if (oldWz == null)
            return;

        index = oldWz.properties.engineItems.findIndex((a: any) => a.Id === payload.engineId);
        enginItem = oldWz.properties.engineItems[index];
        oldWz.properties.engineItems.splice(index, 1);
    }

    let newWz = workZones.find((wz: any) => wz.id === payload.newWzId);

    if (!newWz)
        return;

    index = newWz.properties.engineItems.findIndex((a: any) => a.Id === payload.engineId);
    if (index > -1)
        return;

    if (enginItem == null) {
        enginItem = {
            AssignmentId: 0,
            Id: payload.engineId,
            Name: payload.engineName
        };
    }

    newWz.properties.engineItems.push(enginItem);
    
};

const renewWorkZoneSet = (state:any, payload:any) => {
    let id = payload.id;
    if (id == 0) {
        if (state.workZoneSets[0].id === 0)
            state.workZoneSets.splice(0, 1);

        if (payload.set.workZones.length > 0) {
            id = payload.set.workZones[0].properties.workZoneSetId;
            payload.set.id = id;
        }
    }
    let index = state.workZoneSets.findIndex((s:any) => s.id === id);
    if (index > -1)
        state.workZoneSets.splice(index, 1);

    if (payload.set.dateUpdatedString == "") {
        state.selectedWorkZoneSetId = 0;
    }
    else {
        state.workZoneSets.unshift(payload.set);
        renewWorkZones(state);
    }
};

const doSetChanged = (state: any, payload: boolean) => {
  (window as any).workZonesChanged = payload;
  state.changed = payload;
};

const workZonesSlice = createSlice({
    name: "workzones",
    initialState,
    reducers: {
        setWorkZoneSets: (state, action) => {

            if (!action.payload)
                return;

            let features = action.payload;
            state.workZoneSets.length = 0;//clear

            if (features.length < 1) {
                state.workZoneSets = [];
                return;
            }

            const getSet = (id: number) => {
                return state.workZoneSets.find(s => s.id === id);
            };

            for (let f of features) {
                let setId = f.properties.workZoneSetId;
                let set = getSet(setId);
                if (set) {
                    set.workZones.push(f);
                    continue;
                }

                set = {
                    id: setId,
                    dateUpdatedString: f.properties.dateUpdatedString,
                    workZones: [f]
                };

                state.workZoneSets.push(set);
            }

        },
        addWorkZoneSet: (state, action) => {
            console.log("addWorkZoneSet", action.payload);
            state.workZoneSets.unshift(action.payload);
            state.selectedWorkZoneSetId = 0;
        },
        removeWorkZoneSet: (state, action) => {
            
            console.log("removeWorkZoneSet", action.payload);
            let index = state.workZoneSets.findIndex(s => s.id === action.payload);

            if (index < 0)
                return;

            state.workZoneSets.splice(index, 1);
            if (state.selectedWorkZoneSetId === action.payload) {
                let length = state.workZoneSets.length;
                if (length > 0)
                    setSelectedWorkZoneSet(state.workZoneSets[0]);
                else
                    setSelectedWorkZoneSet(0);
            }
        },
        updateWorkZoneSet: (state, action) => {

            renewWorkZoneSet(state, action.payload);
        },
        setSelectedWorkZoneSet: (state, action) => {
            if (action.payload === -1) {
                state.selectedWorkZoneSetId = -1;
                doSetChanged(state, false);
                renewWorkZones(state)
                return;
            }

            let set = state.workZoneSets.find(s => s.id === action.payload);
            if (!set){
                return;
            }

            doSetChanged(state, false);
            state.selectedWorkZoneSetId = set.id;
            renewWorkZones(state);
        },
        setWorkZones: (state, action) => {
            console.log("setWorkZones", action.payload);
            state.workZones = action.payload;
            updateSelectedWorkZone(state);
        },
        refreshWorkZones: (state) => {
            console.log("refreshWorkZones");
            doSetChanged(state, false);
            renewWorkZones(state);
        },
        setSelectedWorkZoneId: (state, action) => {
            state.selectedWorkZoneId = action.payload;
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
        },
        setSavedState: (state, action) => {
            state.savedState = action.payload;
        },
        setCanEdit: (state, action) => {
            state.canEdit = action.payload;
        },
        setWorkZoneEngines: (state, action) => {
            if (!state.workZones)
                return;

            const setEngines = (workZones: any) => {
                let wz = workZones.find((w: any) => w.id === action.payload.wzId);
                if (wz) {
                    wz.properties.engineItems = deepCopy(action.payload.engines);
                }
            };

            setEngines(state.workZones);

            if (state.editMode)
                return;

            let index = state.workZoneSets.findIndex(s => s.id === state.selectedWorkZoneSetId);
            if (index < 0)
                return;

            let set = state.workZoneSets[index];
            if (set) {
                setEngines(set.workZones);
                state.workZoneSets.splice(index, 1);
                let nowString = action.payload.nowString;
                if (nowString && nowString.length > 5)
                    set.dateUpdatedString = nowString;
                state.workZoneSets.unshift(set);
            }
        },
        moveWorkZoneEngine: (state, action) => {
            moveEngine(state.workZones, action.payload);
        },
        setWorkZoneLabel: (state, action) => {
            if (!state.workZones)
                return;

            const setLabel = (workZones: any) => {
                let wz = workZones.find((z: any) => z.id === action.payload.wzId);
                if (wz) {
                    wz.properties.label = action.payload.label;
                }
            };

            setLabel(state.workZones);
            let index = state.workZoneSets.findIndex(s => s.id === state.selectedWorkZoneSetId);
            if (index < 0)
                return;

            let set = state.workZoneSets[index];
            if (set) {
                setLabel(set.workZones);
                let nowString = action.payload.nowString;
                if (nowString && nowString.length > 5)
                    set.dateUpdatedString = nowString;

                state.workZoneSets.splice(index, 1);
                state.workZoneSets.unshift(set);
            }

        },
        setSelectedProperties: (state, action) => {
            state.selectedProperties = action.payload;
        },
        setShowWorkZoneLabels: (state, action) => {
            state.showWorkZoneLabels = action.payload;
        },
        setDialogParams: (state, action) => {
            const { show, params } = action.payload;
            state.showConfirmDialog = show;
            state.dialogParams = params;
        },
        setFireDetail: (state, action) => {
            state.wdsFireDetail = action.payload;
        },
        setEnginesByFire: (state, action) => {
            state.enginesByFire = action.payload;
        },
        setFirePerimeters: (state, action) => {
            state.wdsFirePerimeter = action.payload;
        },
        setHasMapError: (state, action) => {
            state.hasMapError = action.payload;
        },
        setEditing: (state, action) => {
            state.editing = action.payload;
        },
        setChanged: (state, action) => {
            doSetChanged(state, action.payload);
        },
        setShowCloseConfirm: (state, action) => {
            state.showCloseConfirm = !!(action.payload && state.changed);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPropertiesInFire.fulfilled, (state, action) => {
            state.propertiesInFire = action.payload;
        });
        builder.addCase(submitWorkZones.fulfilled, (state, action) => {
            
            if (action.payload == null || !action.payload)
                return;

            if (!action.payload.features)
                return;

            let features: atlas.data.Feature<any, any>[] = action.payload.features;
            let set = {
                id: state.selectedWorkZoneSetId,
                dateUpdatedString: "",
                workZones: features
            };
            if (features.length > 0)
                set.dateUpdatedString = features[0].properties.dateUpdatedString

            renewWorkZoneSet(state, { id: state.selectedWorkZoneSetId, set: set });
            setSelectedWorkZoneId(undefined);
            state.editMode = false;
            state.savedState = true;
            state.showCloseConfirm = false;
            doSetChanged(state, false);
        });
    },
});


export const {
    setEditMode,
    setSavedState,
    setWorkZoneEngines,
    moveWorkZoneEngine,
    setWorkZoneLabel,
    setSelectedWorkZoneId,
    setWorkZoneSets,
    setSelectedProperties,
    addWorkZoneSet,
    removeWorkZoneSet,
    updateWorkZoneSet,
    setSelectedWorkZoneSet,
    setWorkZones,
    refreshWorkZones,
    setShowWorkZoneLabels,
    setFireDetail,
    setDialogParams,
    setFirePerimeters,
    setEnginesByFire,
    setHasMapError,
    setCanEdit,
    setEditing,
    setChanged,
    setShowCloseConfirm
} = workZonesSlice.actions;

export default workZonesSlice.reducer;
