import { ActionButton } from "@fluentui/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { GetConfigs, INoticeConfigParam, GetClients, GetClientStates } from "api/clientProfileAPI";

interface INotificationConfigState {
  noticeConfigs?: any;
  clients: any;
  editNotification: any;
  clientStates: any;
}

const initialState: INotificationConfigState = {
  noticeConfigs: [],
  clients: [],
  editNotification: {},
  clientStates: []
}

/** API start */
export const getNotificationConfigs = createAsyncThunk("actions/getNotificationConfigs", async (data: any) => {
  let response = await GetConfigs(data);
  return response || [];
});
export const getClientNames = createAsyncThunk("actions/getClientNames", async (param: number[]) => {
  let response = await GetClients(param);
  return response || [];
});
export const getClientStates = createAsyncThunk("actions/getClientStates", async (param: number[]) => {
  let response = await GetClientStates(param);
  return response || [];
});
// export const getClientStates = createAsyncThunk("actions/getClientStates", async (param: INoticeConfigParam) => {
//   let response = await GetStates(param);
//   return response || [];
// });
/** API end */

export const clientProfileSlice = createSlice({
  name: "clientProfile",
  initialState,
  reducers: {
    setEdit: (state, action) => {
      state.editNotification = action.payload;
    }
  },
  extraReducers: builder => {
      builder
          .addCase(getNotificationConfigs.fulfilled, (state, action) => {
            state.noticeConfigs = action.payload;
          })
          .addCase(getClientNames.fulfilled, (state, action) => {
            state.clients = action.payload;
          })
          .addCase(getClientStates.fulfilled, (state, action) => {
            state.clientStates = action.payload;
          })
  }
});

export const {
  setEdit,
} = clientProfileSlice.actions;

export default clientProfileSlice.reducer;