import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetMguPropsNearFire, IPropertyInFireParam } from "../../api/mguPropsAPI";
import { IPropertyInFire } from "../../api/models";

interface IMguPropsState {
  mguProperties?: any;
}

const initialState: IMguPropsState = {
  mguProperties: []
}

/** API start */
export const getMguProps = createAsyncThunk("actions/getMguProps", async (param: IPropertyInFireParam) => {
  let response = await GetMguPropsNearFire(param);
  return response || [];
});
/** API end */

export const mguPropsSlice = createSlice({
  name: "mguProps",
  initialState,
  reducers: {
    setMguProps: (state, action) => {
      state.mguProperties = action.payload;
    }
  },
  extraReducers: builder => {
      builder
          .addCase(getMguProps.fulfilled, (state, action) => {
              state.mguProperties = action.payload;
          });
  }
});

export const {
  setMguProps
} = mguPropsSlice.actions;

export default mguPropsSlice.reducer;