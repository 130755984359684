import { IEnginesKpiBox, IEnginesOnMap, IEngineStatusResult, ITaskForcePersons, ICenter, IAssignment, ILatitudeAndLongitude } from "./models";
import { getJson, postJson, postJsonWithNoTrack, getFileToDownload, linkToDownload } from "utils";
import { Constants } from "../utils/constants";
import { ITaskForce } from "./models/resourceStatus/ITaskForce";
import { IAlliance } from "./models/resourceStatus/IAlliance";
import { IEngineResource } from "./models/resourceStatus/IEngineResource";

/*KpiBox*/
export async function getEnginesKpi(): Promise<IEnginesKpiBox> {
    let result = await getJson("/api/ResourceStatus/Engines/GetEnginesKpi");
    return result ?? {};
}

export async function saveKpiConfigration(configration: any) {
    postJsonWithNoTrack(
        '/api/ResourceStatus/Engines/SaveKpiBoxConfig',
        { configration }
    );
}

export async function getKpiConfigration(): Promise<any> {
    return getJson('/api/ResourceStatus/Engines/GetKpiBoxConfig');
}

/* Task Force */
export async function getTaskForcePersons(): Promise<ITaskForcePersons> {
    let result = await getJson("/api/ResourceStatus/AdAccount/GetPersonsForTaskForce");
    return result ?? {};
}

export async function getAllTaskForces(): Promise<ITaskForce[]> {
    let result = await getJson("/api/ResourceStatus/TaskForce/GetAllTaskForces");
    return result ?? [];
}

export async function getTaskForceById(id: number): Promise<ITaskForce> {
    return getJson(`/api/ResourceStatus/TaskForce/GetTaskForceById/${id}`);
}

export async function saveTaskForce(taskForceViewModel: any): Promise<boolean> {
    let result = await postJson("/api/ResourceStatus/TaskForce/SaveTaskForce", taskForceViewModel);
    return result ?? false;
}

/* Center */
export async function getAllCenters(): Promise<ICenter[]> {
    let result = await getJson("/api/ResourceStatus/Alliance/GetAllCenters");
    return result ?? [];
}

export async function getCenterById(id: number): Promise<IAlliance> {
    return getJson(`/api/ResourceStatus/Alliance/GetAllianceById/${id}`);
}

export async function getLatitudeAndLongitude(allianceViewModel: any): Promise<ILatitudeAndLongitude> {
    let result = await postJson(`/api/ResourceStatus/Alliance/GetLatitudeAndLongitude`, allianceViewModel);
    return result ?? "";
}

export async function saveCenter(allianceViewModel: any): Promise<boolean> {
    let result = await postJson(`/api/ResourceStatus/Alliance/SaveAlliance`, allianceViewModel);
    return result ?? false;
}

/* Resource */
export async function getEngineResourceById(id: number): Promise<IEngineResource> {
    return getJson(`/api/ResourceStatus/Engines/GeEngineResourceById/${id}`);
}

export async function getVinOccupied(id: number, vin: string): Promise<boolean> {
    return getJson(`/api/ResourceStatus/Engines/GetVinOccupied/${id}?vin=${vin}`);
}

export async function saveEngineResource(engineResourceViewModel: any): Promise<boolean> {
    let result = await postJson("/api/ResourceStatus/Engines/SaveEngineResource", engineResourceViewModel);
    return result ?? false;
}

/* Map */
export async function getAssignmentsByEngineId(id: number): Promise<IAssignment[]> {
    return getJson(`/api/ResourceStatus/Engines/GeAssignmentsByEngineId/${id}`);
}

export async function getEnginesOnMap(): Promise<IEnginesOnMap[]> {
    let invalidStatus = Constants.EngineStatus.map(e => e.NameInDB);
    return postJson("/api/ResourceStatus/Engines/GetEnginesOnMap",
        {
            Filter: {
                invalidStatus: invalidStatus
            }
        })
        .then(result => result);
}

export async function getEnginesOnTable(): Promise<IEnginesOnMap[]> {
  return postJson("/api/ResourceStatus/Engines/GetEnginesOnTable", "").then(
    (result) => result
  );
}

/***************** Timeline Start **********************/
export interface IExportEngineStatusParam {
    fileName: string;
    topEngineId?: number;
    engTypeSortOrder?: number; // EnumDataSort 
    selectedEngines: string;
}

// Export all engines status history to an Excel file.
export async function exportEngineStatusToExcel(param: IExportEngineStatusParam) {
    const url = '/api/ResourceStatus/Timeline/ExportEngineStatusToExcel';

    let apiParams = {
        Filter: {
            TopEngineId: param.topEngineId,
            EngTypeSortOrder: param.engTypeSortOrder,
            SelectedEngines: param.selectedEngines,
        }
    };

    let result = await getFileToDownload(url, apiParams);

    if (result && result.size > 0) {
        linkToDownload(result, param.fileName);
    }
    else {
        alert("No Excel file generated to download.");
    }
}

export interface IDisplayEngineStatusParam {
    selectedEngines: string;
}

export async function displayEngineStatus(param: IDisplayEngineStatusParam): Promise<IEngineStatusResult> {
    let apiParams = {
        Filter: {
            SelectedEngines: param.selectedEngines,
        }
    };

    return postJson("/api/ResourceStatus/Timeline/DisplayEngineStatus", apiParams)
        .then(result => {
            return {
                dataset: result?.data?.data,
                totalCount: result?.data?.meta?.totalCount
            }
        });
}