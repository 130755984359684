import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPublishedNotice } from "../../../api/models";
import {
    getPublishedNotices,
    IPublishedNoticeParam,
    getFromToDate,
    IFromToParam,
    getMDSTofTime,
    IMDSTParam,
    mountainTimeFromTimestamp,
    getIsExternal
} from "../../../api/fireOverviewAPI";

interface IPreFireInforState {
    publishedNotices?: IPublishedNotice[];
    fromTo?: string[];
    fromToValue?: number[];
    fromMDST: string;
    toMDST: string;
    minSlider: number;
    maxSlider: number;
    sliderValue: number;
    sliderTime: string;
    currentTime: string;
    isFirstUse: boolean;
    currentPerimeterId?: number;
    pageNumber: number;
    pageSize: number;
    countNotices: number;
    fromDateTime?: string;
    toDateTime?: string;
}

const initialState: IPreFireInforState = {
    fromMDST: "",
    toMDST: "",
    minSlider: 0,
    maxSlider: 0,
    sliderValue: 0,
    sliderTime: "",
    currentTime:"",
    isFirstUse: true,
    pageNumber: 1,
    pageSize: 10,
    countNotices: 0,
}

export const getPublishedNoticesByFilter = createAsyncThunk("actions/getPublishedNotices", async (param: IPublishedNoticeParam) => {
    let response = await getPublishedNotices(param);
    return response || null;
});

export const getFromToByFilter = createAsyncThunk("actions/getFromToDate", async (param: IFromToParam) => {
    let response = await getFromToDate(param);
    return response || null;
});

export const getMDSTofFromBySelect = createAsyncThunk("actions/getMDSTofFrom", async (param: IMDSTParam) => {
    let response = await getMDSTofTime(param);
    return response || null;
});
export const getMDSTofToBySelect = createAsyncThunk("actions/getMDSTofTo", async (param: IMDSTParam) => {
    let response = await getMDSTofTime(param);
    return response || null;
});

export const mtFromTimestamp = createAsyncThunk("actions/mountainTimeFromTimestamp", async (param: number) => {
    let response = await mountainTimeFromTimestamp(param);
    return response || null;
});

export const mt2FromTimestamp = createAsyncThunk("actions/mountainTime2FromTimestamp", async (param: number) => {
    let response = await mountainTimeFromTimestamp(param);
    return response || null;
});

export const getIsExternalForGrid = createAsyncThunk("actions/getIsExternal", async () => {
    let response = await getIsExternal();
    return response || null;
});

export const preFireInforSlice = createSlice({
    name: "preFireInformation",
    initialState,
    reducers: {
        setFromMDST: (state, action) => {
            state.fromMDST = action.payload as string;
        },
        setToMDST: (state, action) => {
            state.toMDST = action.payload as string;
        },
        setSliderValue: (state, action) => {
            state.sliderValue = action.payload as number;
        },
        setMinSlider: (state, action) => {
            state.minSlider = action.payload as number;
        },
        setMaxSlider: (state, action) => {
            state.maxSlider = action.payload as number;
        },
        setSliderTime: (state, action) => {
            state.sliderTime = action.payload as string;
        },
        setCurrentTime: (state, action) => {
            state.currentTime = action.payload as string;
        },
        setIsFirstUse: (state, action) => {
            state.isFirstUse = action.payload as boolean;
        },
        setCurrentPerimeterId: (state, action) => {
            state.currentPerimeterId = action.payload;
        },
        setPublishedNotices: (state, action) => {
            state.publishedNotices = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload as number;
        },
        setFromDateTime: (state, action) => {
            state.fromDateTime = action.payload;
        },
        setToDateTime: (state, action) => {
            state.toDateTime = action.payload;
        },
        
    },
    extraReducers: builder => {
        builder
            .addCase(getPublishedNoticesByFilter.fulfilled, (state, action) => {
                var res = action.payload;

                var tmp = state.publishedNotices;

                if (state.pageNumber == 1) {

                    var tmpArr = new Array(res?.totalCount).fill({
                        publicationDate: "",
                        type: "",
                        fireSummary: "",
                        wdsActions: "",
                        threatenedEnrolled: 0,
                        otherThreatendProperties: 0,
                        evacuations: "",
                        homesLost: "",
                        sizeContainment: "",
                        perimeterId: 0
                    });

                    for (var i = 0; i < res?.dataset.length; i++) {
                        tmpArr[i] = res?.dataset[i];
                    }

                    state.publishedNotices = tmpArr as IPublishedNotice[];

                    if (res?.dataset && res?.dataset.length > 0) {
                        state.currentPerimeterId = res?.dataset[0].perimeterId;
                    }
                }
                else {

                    var startNum = (state.pageNumber - 1) * state.pageSize;

                    if (tmp) {
                        for (var i = 0; i < res?.dataset.length; i++) {
                            tmp[i + startNum] = res?.dataset[i];
                        }

                        state.publishedNotices = tmp;
                    }
                }

                state.countNotices = res?.totalCount;

            })
            .addCase(getFromToByFilter.fulfilled, (state, action) => {
                var res = action.payload;

                state.fromTo = res?.datetimeStr;

                state.fromToValue = res?.datetimeValue;
            })
            .addCase(getMDSTofFromBySelect.fulfilled, (state, action) => {
                var s = action.payload as string;
                if (s) {
                    var ar = s.split(',');
                    state.fromMDST = ar[0];
                    state.minSlider = parseFloat(ar[1]);
                    state.sliderValue = parseFloat(ar[1]);
                }
            })
            .addCase(getMDSTofToBySelect.fulfilled, (state, action) => {
                var s = action.payload as string;
                if (s) {
                    var ar = s.split(',');
                    state.toMDST = ar[0];
                    state.maxSlider = parseFloat(ar[1]);
                }
            })
            .addCase(mtFromTimestamp.fulfilled, (state, action) => {
                state.sliderTime = action.payload as string;
            })
            .addCase(mt2FromTimestamp.fulfilled, (state, action) => {
                state.currentTime = action.payload as string;
            });
    }
});

export const { setFromMDST,
    setToMDST,
    setSliderTime,
    setCurrentTime,
    setSliderValue,
    setMinSlider,
    setMaxSlider,
    setIsFirstUse,
    setCurrentPerimeterId,
    setPublishedNotices,
    setPageNumber,
    setFromDateTime,
    setToDateTime,
} = preFireInforSlice.actions;

export default preFireInforSlice.reducer;
