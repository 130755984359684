import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { IUser, IClient } from "api/models/IAdmin";
import { getClients, getExternalUsers } from "api/adminAPI";
import { RootState } from "app/store";
import { getItem, isNumber } from "utils";

interface IAdminState {
    users: IUser[];
    clients: IClient[];
    selectedClientIds?: number[];
    pageName?: string;
    selectedClientId?: number;
    selectedUserId?: string;
    lastPage?: string;
}

const initialState: IAdminState = {
    users: [],
    clients: []
}

export const fetchUsers = createAsyncThunk('admin/fetchUsers', async () => {
    let response = await getExternalUsers();
    return response || [];
});

export const fetchClients = createAsyncThunk('admin/fetchClients', async () => {
    let response = await getClients();
    return response || [];
});


const admin = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setPageName: (state, action) => {
            state.pageName = action.payload;
        },
        setSelectedClientId: (state, action) => {
            state.selectedClientId = action.payload;
        },
        setSelectedUserId: (state, action) => {
            state.selectedUserId = action.payload;
        },
        setSelectedClientIds: (state, action) => {
            state.selectedClientIds = action.payload;
        },
        setLastPage: (state, action) => {
            state.lastPage = action.payload;
        },
        handleUserUpdates: (state, action) => {
            const { id, data } = action.payload;
            if (id) {
                const { result, resultIndex } = getItem(state.users, (c) => c.id === id);
                if (~resultIndex) {
                    if (data) {
                        state.users[resultIndex] = { ...result, ...data };
                    }
                    else {
                        state.users.splice(resultIndex, 1);
                    }
                }
            }
            else if (data?.id) {
                state.users.push(data);
            }
        },
        handleClientUpdates: (state, action) => {
            const { id, data } = action.payload;
            if (isNumber(id)) {
                const { result, resultIndex } = getItem(state.clients, (c) => c.id === id);
                if (~resultIndex) {
                    if (data) {
                        state.clients[resultIndex] = { ...result, ...data };
                    }
                    else {
                        state.clients.splice(resultIndex, 1);
                    }
                }
            }
            else if (isNumber(data?.id)) {
                state.clients.push(data);
            }
        },
        removeClientIdFromUsers: (state, action) => {
            const { clientId, userIds } = action.payload;
            for (const user of state.users) {
                if (userIds.includes(user.id)) {
                    const cIdx = user.clientIds.indexOf(clientId);
                    if (~cIdx) {
                        user.clientIds.splice(cIdx, 1);
                    }
                }
            }
        }
    },
    extraReducers: builder => {
        builder
            //fetchUsers
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.users = action.payload;
            })
            //fetchClients
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.clients = action.payload;
            });
    }
});

export const {
    setPageName,
    setSelectedClientIds,
    setSelectedUserId,
    setSelectedClientId,
    setLastPage,
    handleUserUpdates,
    handleClientUpdates,
    removeClientIdFromUsers
} = admin.actions;

const users = (state: RootState) => state.admin.users;
const clients = (state: RootState) => state.admin.clients;
const selectedClientIds = (state: RootState) => state.admin.selectedClientIds;
const selectedClientId = (state: RootState) => state.admin.selectedClientId;
const selectedUserId = (state: RootState) => state.admin.selectedUserId;

export const selectUsers = createSelector(
    users,
    clients,
    (allusers: IUser[], allclients: IClient[]): IUser[] => {
        return allusers.map(u => {
            let clientsToAdd: IClient[] = [];
            u.clientIds.forEach(id => {
                const { result } = getItem(allclients, (c) => c.id === id);
                if (result) {
                    clientsToAdd.push(result);
                }
            });
            return { ...u, clients: clientsToAdd };
        });
    }
);

export const selectFileteredUsers = createSelector(
    selectUsers,
    selectedClientIds,
    (sltusers: IUser[], clientids?: number[]): IUser[] => {
        let filteredUsers: IUser[] = [];
        sltusers.forEach(u => {
            if (clientids?.length) {
                if (u.clientIds.some(cid => clientids.indexOf(cid) > -1)) {
                    filteredUsers.push(u);
                }
            }
            else {
                filteredUsers.push(u);
            }
        });

        return filteredUsers;
    }
);


export const selectedUser = createSelector(
    selectUsers,
    selectedUserId,
    (sltusers: IUser[], userId?: string): IUser | null => {
        const { result } = getItem(sltusers, (c) => c.id === userId);
        return result;
    }
);

export const selectClients = createSelector(
    users,
    clients,
    (allusers: IUser[], allclients: IClient[]): IClient[] => {
        return allclients.map(c => {
            let usersToAdd: IUser[] = [];
            allusers.forEach(u => {
                if (u.clientIds.includes(c.id)) {
                    usersToAdd.push(u);
                }
            });
            return { ...c, users: usersToAdd };
        });
    }
);

export const selectedClient = createSelector(
    selectClients,
    selectedClientId,
    (sltclients: IClient[], clientId?: number): IClient | null => {
        const { result } = getItem(sltclients, (c) => c.id === clientId);
        return result;
    }
);

export default admin.reducer;