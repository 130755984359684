import { createSlice } from "@reduxjs/toolkit";

export const EnumFilterKeyWord = {
    ALL: "ALL",
    NONE: "NONE"
}

interface IMapFilterState {
    selectedEngines: string;
    allStatesCount: number;
    selectedStatusCount: number;
}

const initialState: IMapFilterState = {
    selectedEngines: EnumFilterKeyWord.NONE,
    allStatesCount: 0,
    selectedStatusCount: 0,
}

export const mapFilterSlice = createSlice({
    name: "resourceMapFilter",
    initialState,
    reducers: {
        setSelectedEnginesForTabular: (state, action) => {
            
            var v = action.payload;

            state.selectedEngines = v;

        },

        setAllStatesCount: (state, action) => {
            state.allStatesCount = action.payload;
        },

        setSelectedStatusCount: (state, action) => {
            state.selectedStatusCount = action.payload;
        },

    }
});

export const {
    setSelectedEnginesForTabular,
    setAllStatesCount,
    setSelectedStatusCount
} = mapFilterSlice.actions;

export default mapFilterSlice.reducer;