/// <reference path="../../index.d.ts"/>
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    IClient,
    IError,
    ITelemetry,
    IResClient,
    IUSer,
    LogLevel,
} from "api/models";
import { makeError } from "utils";
import {
    getResClients,
    getUserInfo,
    writeEvent,
    writeLog,
    writePageViewEvent,
    writeUserTrackingEvent,
} from "api/commonAPI";

interface IGlobalState {
    title?: string | null;
    loading: boolean;
    errors: Array<IError>;
    clients?: IResClient[];
    userInfo?: IClient | IUSer;
}

const initialState: IGlobalState = {
    title: null,
    loading: false,
    errors: [],
};

export const logInfo = createAsyncThunk(
    "global/logInfo",
    async (message: string) => {
        await writeLog(message, LogLevel.Information.toString());
    }
);

export const logError = createAsyncThunk(
    "global/logError",
    async (message: string) => {
        await writeLog(message, LogLevel.Error.toString());
    }
);

export const trackEvent = createAsyncThunk(
    "global/trackEvent",
    async (name: string) => {
        await writeEvent(name);
    }
);

export const logTrackingEvent = createAsyncThunk(
    "global/writeUserTrackingEvent",
    async (data: Partial<ITelemetry>) => {
        await writeUserTrackingEvent(data);
    }
);

export const trackPageView = createAsyncThunk(
    "global/trackPageView",
    async (data: Partial<ITelemetry>) => {
        await writePageViewEvent(data);
    }
);

export const fetchClients = createAsyncThunk(
    "global/fetchClients",
    async () => {
        return getResClients({ filter: { isActive: true } });
    }
);

export const fetchUserInfo = createAsyncThunk(
    "global/fetchUserInfo",
    async () => {
        return getUserInfo();
    }
);

const global = createSlice({
    name: "global",
    initialState,
    reducers: {
        setPageTitle: (state, action) => {
            state.title = action.payload;
        },
        setLoading: (state) => {
            state.loading = true;
        },
        setUnloading: (state) => {
            if (!window.requestCounter || window.requestCounter <= 0) {
                state.loading = false;
            }
        },
        addError: (state, action) => {
            if (action.payload) {
                const err = makeError(action.payload);
                if (err) {
                    state.errors.push(err);
                }
            }
        },
        deleteError: (state, action) => {
            if (action.payload as string) {
                const index = state.errors.findIndex(
                    (err) => err.id === action.payload
                );
                index > -1 && state.errors.splice(index, 1);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            //fetchClients
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.clients = action.payload;
            })
            //fetchUserInfo
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.userInfo = action.payload;
            });
    },
});

export const { setPageTitle, addError, deleteError, setLoading, setUnloading } =
    global.actions;
export default global.reducer;
