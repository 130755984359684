import { IWFIGSFire, IGeogStates } from "./models";
import { getJson, postJson } from "utils";

/* WFIGS Fires */
export async function getFiresFromWFIGS(): Promise<IWFIGSFire[]> {
    var result = await getJson("/api/MonitoringHeadquarters/Fires/GetFiresFromWFIGS");
    return result ?? [];
}

export async function getGeogStates(): Promise<IGeogStates[]> {
    var result = await getJson("/api/MonitoringHeadquarters/Fires/GetGeoStates");
    return result ?? [];
}

export async function monitorFire(ufi: string): Promise<number> {
    var result = await postJson("/api/MonitoringHeadquarters/Fires/MonitorFire", ufi);
    return result ?? 0;
}

export async function removeFire(ufi: string): Promise<boolean> {
    var result = await postJson("/api/MonitoringHeadquarters/Fires/RemoveFire", ufi);
    return result ?? false;
}
