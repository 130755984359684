export enum LogLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export const EventName = {
    MAP_LAYER: 'MAP LAYER',
    VIEW_ITEM: 'VIEW LIST ITEM',
    FILTER: 'FILTER',
    DATA_CHANGE: 'DATA CHANGED'
}