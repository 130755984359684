import DispatchedFires from "./DispatchedFires.png";
import NonDispatchedFires from "./Non-DispatchedFires.png";
import MonitoredFires from "./MonitoredFires.svg";
import NoteworthyFires from "./NoteworthyFires.svg";
import ActiveFireEngine from "./ActiveFireEngine.png";
import InactiveFireEngine from "./InactiveFireEngine.png";
import ManualFireEngine from "./ManualFireEngine.png";
import UnknownFireEngine from "./UnknownFireEngine.png";
import IntterraFire from "./IntterraFireIcon.png";
import WDSFireIcon from "./WDSFireIcon.png";
import DemobilizedFires from "./DemobilizedFires.png";
import EventPointBranchBreak from "../layerSymbols/branch_break.png";
import EventPointFireOrigin from "../layerSymbols/fire_origin.png";
import EventPointDivisionBreak from "../layerSymbols/division_break.png";
import EventPointZoneBreak from "../layerSymbols/zone_break.png";
import EventPointIncidentCommandPost from "../layerSymbols/IC_post.png";
import EventPointHotSpot from "../layerSymbols/hot_spot.png";
import EventPointFireStation from "../layerSymbols/fire_station.png";
import EventPointSafetyZone from "../layerSymbols/safety_zone.png";
import EventPointWaterSource from "../layerSymbols/water_source.png";
import IRPointHeatSource from "../layerSymbols/ir_isolated.png";
import IRPointPossibleSource from "../layerSymbols/possible_ir_heat.png";
import ResClientEnrolledCluster from "./RecCluster.png";
import ResClientNotEnrolledCluster from "./ReneCluster.png";
import ResClientOtherCluster from "./RecoCluster.svg";
import CommClientEnrolledCluster from "./CceCluster.png";
import CommClientNotEnrolledCluster from "./CcneCluster.png";
import CommClientOtherCluster from "./CcoCluster.svg";
import AgriculturalClientEnrolledCluster from "./ArgECluster.png";
import AgriculturalClientNotEnrolledCluster from "./ArgNeCluster.png";
import AgriculturalClientOtherCluster from "./ArgOCluster.svg";
import MGUCluster from "./MGUCluster.png";
import PspLocationPoint from "../layerSymbols/psp_location_point.png";
import VisitedFlag from "./VisitedFlag.svg";
import RehabRequiredFlag from "./RehabRequiredFlag.svg";

export const DispatchedFiresIcon = DispatchedFires;
export const NonDispatchedFiresIcon = NonDispatchedFires;
export const MonitoredFiresIcon = MonitoredFires;
export const NoteworthyFiresIcon = NoteworthyFires;
export const ActiveFireEngineIcon = ActiveFireEngine;
export const InactiveFireEngineIcon = InactiveFireEngine;
export const ManualFireEngineIcon = ManualFireEngine;
export const UnknownFireEngineIcon = UnknownFireEngine;
export const IntterraFireIcon = WDSFireIcon;
export const WDSFireIconIcon = IntterraFire;
export const DemobilizedFiresIcon = DemobilizedFires;
export const EventPointBranchBreakIcon = EventPointBranchBreak;
export const EventPointFireOriginIcon = EventPointFireOrigin;
export const EventPointDivisionBreakIcon = EventPointDivisionBreak;
export const EventPointZoneBreakIcon = EventPointZoneBreak;
export const EventPointIncidentCommandPostIcon = EventPointIncidentCommandPost;
export const EventPointHotSpotIcon = EventPointHotSpot;
export const EventPointFireStationIcon = EventPointFireStation;
export const EventPointSafetyZoneIcon = EventPointSafetyZone;
export const EventPointWaterSourceIcon = EventPointWaterSource;
export const IRPointHeatSourceIcon = IRPointHeatSource;
export const IRPointPossibleSourceIcon = IRPointPossibleSource;
export const ResClientEnrolledClusterIcon = ResClientEnrolledCluster;
export const ResClientNotEnrolledClusterIcon = ResClientNotEnrolledCluster;
export const ResClientOtherClusterIcon = ResClientOtherCluster;
export const CommClientEnrolledClusterIcon = CommClientEnrolledCluster;
export const CommClientNotEnrolledClusterIcon = CommClientNotEnrolledCluster;
export const CommClientOtherClusterIcon = CommClientOtherCluster;
export const AgriculturalClientEnrolledClusterIcon = AgriculturalClientEnrolledCluster;
export const AgriculturalClientNotEnrolledClusterIcon = AgriculturalClientNotEnrolledCluster;
export const AgriculturalClientOtherClusterIcon = AgriculturalClientOtherCluster;
export const MGUClusterIcon = MGUCluster;
export const PspLocationPointIcon = PspLocationPoint;
export const VisitedFlagIcon = VisitedFlag;
export const RehabRequiredFlagIcon = RehabRequiredFlag;