import { IActionTypesMap, IDailyEntries, IDailyTime, IDailyTimeDetail, IActionType, IDailyHours } from "api/models";
import { postJson, groupBy } from "utils";

//Data Table
export async function getDailyTime(query: any): Promise<Array<IDailyTime>> {
    return postJson('api/timeEngine/timeEngine/dailyTimes', query).then(res => res.data || []);
}

//Action Space
export async function getDailyTimeDetail(id: number): Promise<IDailyTimeDetail> {
    return postJson('api/timeEngine/timeEngine/details', { Id: id });
}

export async function getDailyEntries(data: any): Promise<IDailyEntries> {
    return postJson('api/timeEngine/timeEngine/entries', data);
}

export async function getDailyHours(billableApproved: number): Promise<IDailyHours> {
    return postJson('api/timeEngine/timeEngine/hours', { BillableApproved : billableApproved });
}

export async function getRemovedEntries(id: number): Promise<IDailyEntries> {
    return postJson('api/timeEngine/timeEngine/removed', { Id: id });
}

export async function approveDailyTime(detail: Partial<IDailyTimeDetail>): Promise<void> {
    return postJson(`api/timeEngine/timeEngine/approve`, detail);
}

export async function rejectDailyTime(detail: Partial<IDailyTimeDetail>): Promise<void> {
    return postJson(`api/timeEngine/timeEngine/reject`, detail);
}

export async function getTimeStatuses(query: any) {
    return postJson('api/timeEngine/timeEngine/status', query);
}

export async function saveDailyEntry(detail: Partial<IDailyEntries>): Promise<void> {
    return postJson(`api/timeEngine/timeEngine/saveEntry`, detail);
}

export async function saveDailyHour(detail: Partial<IDailyHours>): Promise<void> {
    return postJson(`api/timeEngine/timeEngine/saveDailyHour`, detail);
}

export async function getNPActionTypes(): Promise<IActionTypesMap> {
    return postJson('api/timeEngine/timeEngine/NPActionTypes', {}).then(res => res.data || [])
};

export async function getActionTypes(): Promise<IActionTypesMap> {
    return postJson('api/pha/common/ActionTypes', {}).then(res => {
        return groupBy<IActionType, 'category'>(res.data || [], 'category');
    });

};

export async function getHomeStatusOptions(): Promise<string[]> {
    return postJson('api/pha/visits/homestatus', {}).then(res => res.data || []);
};

export async function getPropertyAddresses(data: any): Promise<any> {
    return postJson('/api/pha/visits/getpropertyaddresses', data);
};

export async function saveNewwNonPropertyAction(data: any): Promise<any> {
    const saved = await postJson('/api/timeEngine/timeEngine/savenewnonproperty', data).then(res => res || 0);
    return saved;
}