export const PAGE_TITLE_PHA = "Policyholder Action - WDS";
export const PAGE_TITLE_SITMAP = "SitMap - WDS";
export const PAGE_TITLE_PLP = "Response Landing Page - WDS";
export const PAGE_TITLE_FDE = "Fire Data Entry - WDS";
export const PAGE_TITLE_SCP = "Smoke Check - WDS";
export const PAGE_TITLE_Risk = "Risk - WDS";
export const PAGE_TITLE_FireOverview = "Fire Overview - WDS";
export const PAGE_TITLE_PSP = "Property Search - WDS";
export const PAGE_TITLE_Notifications = "Notifications - WDS";
export const PAGE_TITLE_HistoricFires = "Historic Fires - WDS";
export const PAGE_TITLE_ProgramUse = "Program Use - WDS";
export const PAGE_TITLE_ResourceStatus = "Resource Status - WDS";
export const PAGE_TITLE_MonitoringHeadquarters = "Monitoring Headquarters - WDS";
export const PAGE_TITLE_ResourceScheduling = "Resource Scheduling - WDS";
export const PAGE_TITLE_WorkflowManagement = "Workflow Management - WDS";
export const PAGE_TITLE_DailyAssignments = "Daily Assignments - WDS";
export const PAGE_TITLE_ClientSubscription = "Client Subscription - WDS";
export const PAGE_TITLE_ApiManagement = "API Management - WDS";
export const PAGE_TITLE_Admin: string = "User Management - WDS";
export const PAGE_TITLE_TimeEngine = "Engine Time - WDS";

export const AUTH_COOKIE: string = "WDS.Authorized";
export const AUTH_EDITOR: string = "PHAEditor";
export const AUTH_READER: string = "PHAReader";
export const SIGNIN_PATH: string = "/microsoftidentity/account/signin";
export const SIGNOUT_PATH: string = "/microsoftidentity/account/signout";
export const IDLE_TIME: number = 120000;

export const SITE_INTERNAL: string = 'Internal';
export const SITE_EXTERNAL: string = 'External';

export const REVIEWSTATUS_PUBLISHED: string = "published";
export const REVIEWSTATUS_REVIEWED: string = "reviewed";
export const REVIEWSTATUS_REMOVED: string = "Removed";

export const PHA_DATATABLE_PAGESIZE: number = 20;

export const FIREMAP_DATATABLE_PAGESIZE: number = 10;
export const PLP_DATATABLE_PAGESIZE: number = 20;
export const PSP_DATATABLE_PAGESIZE: number = 20;

export const ERROR_PREFIX: string = "wds-error";

export const GOOGLE_EARTH: string = "https://earth.google.com/web/search/";
//residential
export const WDS_LOB_HOM = 1;
//commercial
export const WDS_LOB_BUS = 2;
//agricultural
export const WDS_LOB_AGR = 3;
//MGU
export const WDS_LOB_MGU = 4
//property search page
export const PSP_TAB_GENERAL = 1
export const PSP_TAB_ENROLLMENT_ACCESS = 1
export const PSP_TAB_LOCATION = 3
export const PSP_TAB_SERVICES = 4
export const PSP_TAB_CALL_LOG = 5
export const PSP_TAB_WTF = 6
//threat perimeter page
export const TPP_NO_THREAT = 1
export const TPP_THREAT_NoDipatch = 2
export const TPP_THREAT_Dipatched = 3
export const TPP_THREAT_OnIncident = 4
//client subscription page
export const CS_TAB_SUBSCRIPTION = 1
export const CS_TAB_CONFIGURATION = 2
//Api management
export const AUTH_APIM_EDITOR: string = "AMNEditor";