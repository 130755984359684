import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INotificationsFireInfo, IPreNoticesDate, INoticeTypeSection, INotificationClient, INoticesByDateParam, INotifications, INotificationDefaultValues } from "../../api/models";
import { getFireInfo, getPreNoticesDate, getNoticesByDate, getLatestNotices, saveNotifications, saveTriggered, checkIfFunctionAppCompleted, getNotificationDefaultValues, getAllClients } from "../../api/notificationsAPI";
import { Constants } from "../../utils/constants";

//define the state for Notifications
export interface NotificationsState {
    fireId: number;
    fireInformation: INotificationsFireInfo;
    originalFireSummary: string,
    fireSummary: string,
    originalHomesLost: string,
    homesLost: string,
    originalEvacuations: string,
    evacuations: string,
    isValid: boolean,
    preNoticesDate: IPreNoticesDate[],
    originalNoticeTypeSections: INoticeTypeSection[],
    noticeTypeSections: INoticeTypeSection[],
    noticesByDate: INotificationClient[],
    latestNotices: INotificationClient[],
    isSavedCompleted: boolean,
    newNoticeIds: number[],
    notificationDefaultValues: INotificationDefaultValues,
    selectedPreNoticesDate: string,
    statusQueryGetUri: string,
    statusOfFunctionApp: string,
    allClients: []
}

const initialState: NotificationsState = {
    fireId: 0,
    fireInformation: {},
    originalFireSummary: '',
    fireSummary: '',
    originalHomesLost: Constants.HomesLost[0],
    homesLost: Constants.HomesLost[0],
    originalEvacuations: Constants.Evacuations[0],
    evacuations: Constants.Evacuations[0],
    isValid: true,
    preNoticesDate: [],
    originalNoticeTypeSections: [],
    noticeTypeSections: [],
    noticesByDate: [],
    latestNotices: [],
    isSavedCompleted: false,
    newNoticeIds: [],
    notificationDefaultValues: {},
    selectedPreNoticesDate: 'None Available',
    statusQueryGetUri: '',
    statusOfFunctionApp: '',
    allClients: []
}

export const fetchFireInformation = createAsyncThunk('actions/fetchFireInformation', async (id: number) => {
    let response = await getFireInfo(id);
    return response || {};
});

export const fetchPreNoticesDate = createAsyncThunk('actions/fetchPreNoticesDate', async (fireId: number) => {
    let response = await getPreNoticesDate(fireId);
    return response || [];
});

export const fetchNotificationDefaultValues = createAsyncThunk('actions/fetchNotificationDefaultValues', async (param: INoticesByDateParam) => {
    let response = await getNotificationDefaultValues(param);
    return response || [];
});

export const fetchNoticesByDate = createAsyncThunk('actions/fetchNoticesByDate', async (param: INoticesByDateParam) => {
    let response = await getNoticesByDate(param);
    return response || [];
});

export const fetchLatestNotices = createAsyncThunk('actions/fetchLatestNotices', async (fireId: number) => {
    let response = await getLatestNotices(fireId);
    return response || [];
});

export const fetchAllClients = createAsyncThunk('actions/fetchAllClients', async () => {
    let response = await getAllClients();
    return response || [];
});

export const saveNotificationsAction = createAsyncThunk('actions/saveNotificationsAction', async (notifications: INotifications) => {
    let response = await saveNotifications(notifications);
    return response || [];
});

export const saveTriggeredAction = createAsyncThunk('actions/saveTriggeredAction', async (noticeIds: number[]) => {

    let response = await saveTriggered(noticeIds);
    return response || {};
});

export const checkIfFunctionAppCompletedAction = createAsyncThunk('actions/checkIfFunctionAppCompletedAction', async (statusQueryGetUri: string) => {

    let response = await checkIfFunctionAppCompleted(statusQueryGetUri);
    return response || {};
});

/** State slice with reducers, link reducer and action */
export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setFireId: (state, action) => {
            state.fireId = action.payload;
        },
        setOriginalFireSummary: (state, action) => {
            state.originalFireSummary = action.payload;
        },
        setOriginalHomesLost: (state, action) => {
            state.originalHomesLost = action.payload;
        },
        setOriginalEvacuations: (state, action) => {
            state.originalEvacuations = action.payload;
        },
        setFireSummary: (state, action) => {
            state.fireSummary = action.payload;
        },
        setHomesLost: (state, action) => {
            state.homesLost = action.payload;
        },
        setEvacuations: (state, action) => {
            state.evacuations = action.payload;
        },
        setIsValid: (state, action) => {
            state.isValid = action.payload;
        },
        setNoticeTypeSections: (state, action) => {
            state.noticeTypeSections = action.payload;
        },
        setOriginalNoticeTypeSections: (state, action) => {
            state.originalNoticeTypeSections = action.payload;
        },
        setSelectedPreNoticesDate: (state, action) => {
            state.selectedPreNoticesDate = action.payload;
        },
        setIsSavedCompleted: (state, action) => {
            state.isSavedCompleted = action.payload;
        },
        setStatusQueryGetUri: (state, action) => {
            state.statusQueryGetUri = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFireInformation.fulfilled, (state, action) => {
                state.fireInformation = action.payload;
            })
            .addCase(fetchPreNoticesDate.fulfilled, (state, action) => {
                state.preNoticesDate = action.payload;
            })
            .addCase(fetchNotificationDefaultValues.fulfilled, (state, action) => {
                state.notificationDefaultValues = action.payload as INotificationDefaultValues;
            })
            .addCase(fetchNoticesByDate.fulfilled, (state, action) => {
                state.noticesByDate = action.payload;
            })
            .addCase(fetchLatestNotices.fulfilled, (state, action) => {
                state.latestNotices = action.payload;
            })
            .addCase(saveNotificationsAction.fulfilled, (state, action) => {
                state.isSavedCompleted = true;
                state.newNoticeIds = action.payload;
            })
            .addCase(saveTriggeredAction.fulfilled, (state, action) => {
                state.statusQueryGetUri = action.payload;
            })
            .addCase(checkIfFunctionAppCompletedAction.fulfilled, (state, action) => {
                state.statusOfFunctionApp = action.payload;
            })
            .addCase(fetchAllClients.fulfilled, (state, action) => {
                state.allClients = action.payload;
            })

    }
});

/** Action creators are generated for each case reducer function */
export const {
    setFireId,
    setOriginalFireSummary,
    setOriginalHomesLost,
    setOriginalEvacuations,
    setFireSummary,
    setHomesLost,
    setEvacuations,
    setIsValid,
    setOriginalNoticeTypeSections,
    setNoticeTypeSections,
    setSelectedPreNoticesDate,
    setIsSavedCompleted,
    setStatusQueryGetUri
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

