import { createSlice } from "@reduxjs/toolkit";

import { EventInput } from "@fullcalendar/react";

import { IEngineWithSchedule } from "api/models";

const _cellBackgroundColor = (assignment:string):string => {
    var s = "";
    if (assignment == "Response") {
        s = "#F1CDCD";
    }
    else if (assignment == "Ready") {
        s =  "#CFE8CD";
        }
    else if (assignment == "On Hold") {
        s =  "#ABE2F0";
        }
    else {
        s = "#C2C5C1";
    }
    return s;
}

interface ICalendarState {
    enginesWithSchedules?: IEngineWithSchedule[];
    events?: EventInput[];
    crewsPopId: string;
    refreshSchedules: boolean;
}

const initialState: ICalendarState = {
    crewsPopId: "RESCREWSPOP",
    refreshSchedules: false
}

export const calendarSlice = createSlice({
    name: "scheduleCalendar",
    initialState,
    reducers: {
        setEnginesWithSchedules: (state, action) => {
            var data = action.payload;

            state.enginesWithSchedules = data;

            // Translate to calendar events;
            var eventsData: EventInput[] = [];

            for (const item of data) {
                for (const jtem of item.engineScheduleViewModels) {
                    eventsData.push({
                            id: jtem.scheduleId,
                            resourceId: item.id,
                            start: jtem.startDate,
                            end: jtem.endDate,
                            backgroundColor: _cellBackgroundColor(jtem.assignment),
                            borderColor: _cellBackgroundColor(jtem.assignment),
                            extendedProps: {
                                resourceId: item.id,
                                assignment: jtem.assignment,
                                resourceOrderId: jtem.resourceOrderId,
                                fireName: jtem.fireName
                            }
                    });

                    for (const ktem of jtem.personalScheduleViewModels) {
                        eventsData.push({
                            id: jtem.scheduleId,
                            resourceId: item.id,
                            start: jtem.startDate,
                            end: jtem.endDate,
                            backgroundColor: "#f3f3f3",
                            extendedProps: {
                                resourceId: item.id,
                                firstName: ktem.firstName,
                                lastName: ktem.lastName,
                                crewName: ktem.lastName + ", " + ktem.firstName,
                                assignment: "Crew",
                                crewActive: ktem.isActive,
                                crews: jtem.personalScheduleViewModels
                            }
                        });
                    }
                }
            }

            state.events = eventsData;
        },

        setRefreshSchedules: (state, action) => {
            state.refreshSchedules = action.payload;
        },
    },
});

export const {
    setEnginesWithSchedules,
    setRefreshSchedules,
} = calendarSlice.actions;

export default calendarSlice.reducer;